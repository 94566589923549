import { Provider } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { AccountStore, CountriesStore, LocalizationStore, LocationsStore } from './features';
import { LocalizationEffects } from './features/localization/localization.effects';
import { LocationsEffects } from './features/locations/locations.effects';
import { SharedFeatureEventBus } from './shared-feature.event-bus';
import { SharedFeatureFacade } from './shared-feature.facade';
import { SHARED_FEATURE_KEY } from './shared-feature.keys';
import { sharedFeatureReducers } from './shared-feature.reducers';

export const SharedDataAccessStateProviders: Provider = [
    AccountStore,
    LocalizationStore,
    LocationsStore,
    CountriesStore,
    SharedFeatureFacade,
    SharedFeatureEventBus,
    provideState(SHARED_FEATURE_KEY.FEATURE, sharedFeatureReducers),
    provideEffects([
        //
        LocalizationEffects,
        LocationsEffects,
    ]),
];
