import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'p-ui-placeholder',
    templateUrl: './placeholder.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
    styles: [
        `
            :host {
                pointer-events: none;
            }
        `,
    ],
})
export class PlaceholderComponent {
    @Input({ required: true }) public titleIcon!: string;
    @Input({ required: true }) public title!: string;
    @Input({ required: true }) public ctaIcon!: string;
    @Input({ required: true }) public ctaText!: string;
    @Input({ required: false }) public isIconOutlined!: boolean;
    @Input({ transform: coerceBooleanProperty }) public disabled: boolean = false;

    public readonly submitCta = output<void>();
}
