import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
    selector: 'lib-ui-top-progress',
    template: `
        <div class="relative h-2">
            @if ($progress()) {
                <mat-progress-bar mode="indeterminate" class="absolute top-0" />
            }
        </div>
    `,
    imports: [MatProgressBar],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class TopProgressSnippet {
    public readonly $progress = input.required<boolean>({ alias: 'progress' });
}
