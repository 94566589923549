<div class="flex h-full w-full flex-col overflow-hidden" [formGroup]="editFormGroup">
    <div class="mb-2 flex items-center justify-between p-8 text-xl">
        <span class="font-medium">My profile</span>
        <button mat-icon-button (click)="onClose()" [matTooltip]="'Close'">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="form-density w-full grow overflow-auto px-5 xl:px-20">
        <div class="flex items-center gap-4">
            <mat-icon class="text-ds-bright-500 !h-[4.5rem] !w-[4.5rem] text-7xl">account_circle</mat-icon>
            <div>
                <div class="text-xl font-medium text-gray-900">{{ store.user()?.firstName }} {{ store.user()?.lastName }}</div>
                <div class="text-sm text-gray-900">{{ store.user()?.email }}</div>
            </div>
        </div>

        @if (!$changePasswordMode()) {
            <button mat-button class="ml-[5.5rem] mt-6" (click)="onToggle()">Change password</button>
        } @else {
            <div class="flex w-full flex-col sm:pl-[5.5rem]">
                <div class="mt-6 flex w-full flex-col">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Current password</mat-label>
                        <input
                            matInput
                            [type]="$currentPasswordHidden() ? 'password' : 'text'"
                            placeholder="Current password"
                            formControlName="currentPassword"
                            #inputCurrentPasswordElement
                            (focus)="inputCurrentPasswordElement.select()"
                        />
                        <button
                            mat-icon-button
                            matSuffix
                            class="mr-2"
                            type="button"
                            (click)="$currentPasswordHidden.set(!$currentPasswordHidden())"
                            [attr.aria-label]="'Toggle ' + ($currentPasswordHidden() ? 'show' : 'hide')"
                            [attr.aria-pressed]="$currentPasswordHidden()"
                        >
                            <mat-icon class="text-ds-dark-300">{{ $currentPasswordHidden() ? 'visibility_off' : 'visibility' }} </mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div class="py-4">
                    <mat-divider />
                </div>

                <div class="flex w-full flex-col">
                    <div class="flex justify-end">
                        <div class="text-ds-purple-500 cursor-pointer" (click)="onGeneratePassword()">Generate password</div>
                    </div>
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>New password</mat-label>

                        <input
                            matInput
                            [type]="$passwordHidden() ? 'password' : 'text'"
                            placeholder="New password"
                            formControlName="newPassword"
                            #inputElement
                            (focus)="inputElement.select()"
                        />
                        @if (inputElement.value) {
                            <button
                                matSuffix
                                mat-icon-button
                                aria-label="Copy"
                                (click)="onCopyToClipboard(inputElement.value)"
                                matTooltip="Copy"
                            >
                                <mat-icon class="text-ds-dark-300 outlined">content_copy</mat-icon>
                            </button>
                        }

                        <button
                            mat-icon-button
                            matSuffix
                            class="mr-2"
                            type="button"
                            (click)="$passwordHidden.set(!$passwordHidden())"
                            [attr.aria-label]="'Toggle ' + ($passwordHidden() ? 'show' : 'hide')"
                            [attr.aria-pressed]="$passwordHidden()"
                        >
                            <mat-icon class="text-ds-dark-300">{{ $passwordHidden() ? 'visibility_off' : 'visibility' }} </mat-icon>
                        </button>
                    </mat-form-field>
                    <span class="mb-6 w-full text-xs text-gray-400">Mix uppercase and lowercase. Use numbers and special characters.</span>
                </div>

                <div class="flex w-full flex-col">
                    <mat-form-field appearance="outline" class="w-full">
                        <mat-label>Confirm password</mat-label>

                        <input
                            matInput
                            [type]="$passwordHidden() ? 'password' : 'text'"
                            placeholder="Confirm password"
                            formControlName="newPasswordConfirm"
                            #inputConfirmElement
                            (focus)="inputConfirmElement.select()"
                        />
                    </mat-form-field>
                </div>
            </div>
        }
    </div>

    @if ($changePasswordMode()) {
        <div class="flex flex-row justify-end gap-3 p-4">
            <button mat-button (click)="onToggle()">Cancel</button>
            <button
                mat-raised-button
                class="primary"
                (click)="onEdit()"
                [disabled]="editFormGroup.invalid || editFormGroup.pristine || store.updatePasswordProgress()"
            >
                @if (store.updatePasswordProgress()) {
                    <mat-spinner [diameter]="25" />
                } @else {
                    Change password
                }
            </button>
        </div>
    } @else {}
</div>
