/* eslint-disable @angular-eslint/no-output-rename */
import { booleanAttribute, ChangeDetectionStrategy, Component, ElementRef, input, output, Signal, signal, viewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'p-ui-search-bar',
    templateUrl: './search-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatFormFieldModule, ReactiveFormsModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule],
})
export class SearchBarComponent {
    protected readonly $input: Signal<ElementRef<HTMLInputElement>> = viewChild.required('input');

    public readonly $instant = input(false, { alias: 'instant', transform: booleanAttribute });
    public readonly $placeholder = input('Search', { alias: 'placeholder' });

    public readonly searchTerm = output<string>({ alias: 'searchQuery' });
    public readonly searchTermReset = output<void>();
    public readonly closed = output<void>();

    public readonly formControl: FormControl<string | null> = new FormControl<string | null>(null);
    readonly #focusSource = signal(false);

    public onSubmit($event: Event) {
        $event.preventDefault();
        this.#search();
    }

    public onFocus(): void {
        this.#focus();
    }

    public onBlur(): void {
        this.#focusSource.set(false);
    }

    public onSearch() {
        this.#search();
    }

    public onClear(): void {
        this.#clear();
        this.searchTermReset.emit();
        this.#focus();
    }

    public onClose(): void {
        this.#clear();
        this.searchTermReset.emit();
        this.closed.emit();
    }

    public onEscape() {
        this.onClose();
        this.$input().nativeElement.blur();
    }

    #focus(): void {
        this.$input().nativeElement.focus();
        this.#focusSource.set(true);
    }

    #search(): void {
        const currentSearch = this.#getCurrentSearch();
        if (!currentSearch) {
            this.searchTermReset.emit();
        } else {
            this.searchTerm.emit(currentSearch);
        }
    }

    #getCurrentSearch(): string | null {
        const searchValue = this.formControl.value?.trim() ?? '';
        return searchValue !== '' ? searchValue : null;
    }

    #clear(): void {
        this.formControl.reset();
    }
}
