import { AfterViewInit, ChangeDetectionStrategy, Component, computed, input, OnDestroy, output, viewChild } from '@angular/core';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

interface PhotoswipeImage {
    image: string;
    imageHeight: number;
    imageWidth: number;
}

@Component({
    selector: 'lib-ui-photoswipe',
    imports: [],
    templateUrl: './photoswipe.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoswipeComponent<T extends PhotoswipeImage> implements AfterViewInit, OnDestroy {
    public readonly $imageModel = input.required<T>({ alias: 'imageModel' });
    public readonly closed = output<void>();

    public readonly photoswipe = viewChild.required('photoswipeRef');

    public readonly $image = computed(() => this.$imageModel().image);
    public readonly $imageWidth = computed(() => this.$imageModel().imageWidth);
    public readonly $imageHeight = computed(() => this.$imageModel().imageHeight);

    private options = {
        gallery: '#gallery',
        children: 'a',
        pswpModule: PhotoSwipe,
        bgOpacity: 0.32,
    };

    public readonly lightbox: PhotoSwipeLightbox = new PhotoSwipeLightbox(this.options);

    public ngAfterViewInit(): void {
        this.lightbox.init();
        this.lightbox.loadAndOpen(0);
        this.lightbox.on('close', () => {
            this.close();
            this.closed.emit();
        });
    }

    public ngOnDestroy(): void {
        this.close();
    }

    private close(): void {
        this.lightbox?.destroy();
    }
}
