import { ConnectionPositionPair } from '@angular/cdk/overlay';

import { HorizontalConnections, VerticalConnections } from './overlay-positions';
import { PortalPlacement } from './portal-placement';

const INDENTATION: number = 12;

const VERTICAL_BOTTOM_CENTER_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
];

const VERTICAL_TOP_CENTER_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
];

const HORIZONTAL_RIGHT_CENTER_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.center },
        INDENTATION,
        0
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.center },
        -INDENTATION,
        0
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.center }
    ),
];

const HORIZONTAL_RIGHT_TOP_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top },
        INDENTATION,
        0
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.top },
        -INDENTATION,
        0
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
];

const HORIZONTAL_LEFT_CENTER_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.center },
        -INDENTATION,
        0
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.center },
        INDENTATION,
        0
    ),
];

const VERTICAL_BOTTOM_START_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
];

const VERTICAL_BOTTOM_START_PRIORITY_HORIZONTAL: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.center },
        -INDENTATION,
        0
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.center },
        INDENTATION,
        0
    ),
];

const VERTICAL_BOTTOM_END_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
];

const START_CENTER_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.center },
        0,
        INDENTATION
    ),
];

const START_END_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.top }
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top }
    ),
];

const VERTICAL_TOP_START_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
];

const VERTICAL_TOP_END_PRIORITY: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
];

const START_CENTER_END_CENTER: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.center },
        -INDENTATION,
        0
    ),
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.center },
        INDENTATION,
        0
    ),
];

const LEFT: ConnectionPositionPair[] = [
    // Try to align the overlay to the top-left of host element
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.top },
        INDENTATION,
        0
    ),
    // If it doesn't fit, try to align the overlay to the center-left of the host element
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.center },
        INDENTATION,
        0
    ),
    // If it still doesn't fit, align the overlay to the bottom-right of the host element
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.bottom },
        INDENTATION,
        0
    ),
];

const RIGHT: ConnectionPositionPair[] = [
    // Try to align the overlay to the top-right of host element
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top },
        -INDENTATION,
        0
    ),
    // If it doesn't fit, try to align the overlay to the center-right of the host element
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.center },
        -INDENTATION,
        0
    ),
    // If it still doesn't fit, align the overlay to the bottom-right of the host element
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.bottom },
        -INDENTATION,
        0
    ),
];

export const CommonPortalPositionsMap: Map<PortalPlacement, ConnectionPositionPair[]> = new Map<PortalPlacement, ConnectionPositionPair[]>([
    [PortalPlacement.TopCenter, VERTICAL_TOP_CENTER_PRIORITY],
    [PortalPlacement.BottomCenter, VERTICAL_BOTTOM_CENTER_PRIORITY],
    [PortalPlacement.LeftCenter, HORIZONTAL_LEFT_CENTER_PRIORITY],
    [PortalPlacement.RightCenter, HORIZONTAL_RIGHT_CENTER_PRIORITY],
    [PortalPlacement.RightTop, HORIZONTAL_RIGHT_TOP_PRIORITY],
    [PortalPlacement.BottomStart, VERTICAL_BOTTOM_START_PRIORITY],
    [PortalPlacement.BottomEnd, VERTICAL_BOTTOM_END_PRIORITY],
    [PortalPlacement.StartCenter, START_CENTER_PRIORITY],
    [PortalPlacement.StartEnd, START_END_PRIORITY],
    [PortalPlacement.TopStart, VERTICAL_TOP_START_PRIORITY],
    [PortalPlacement.TopEnd, VERTICAL_TOP_END_PRIORITY],
    [PortalPlacement.BottomStartHorizontal, VERTICAL_BOTTOM_START_PRIORITY_HORIZONTAL],
    [PortalPlacement.StartCenterEndCenter, START_CENTER_END_CENTER],
    [PortalPlacement.LeftToRight, [...LEFT, ...RIGHT]],
    [PortalPlacement.RightToLeft, [...RIGHT, ...LEFT]],
]);
