@for (addressGroup of addressFormGroups; track addressGroup; let i = $index) {
    <div class="flex w-full" [class.mt-6]="i > 0">
        <div class="flex w-full flex-col gap-3" [formGroup]="addressGroup">
            @if (addressOptions && addressOptions.length) {
                <mat-select formControlName="label" class="label-width-override max-w-xs !text-sm">
                    @for (option of addressOptions; track option) {
                        <mat-option [value]="option">
                            {{ option }}
                        </mat-option>
                    }
                </mat-select>
            }
            <div class="relative flex w-full flex-row gap-2">
                <div class="flex w-full flex-col flex-wrap pr-9 xl:pr-0">
                    <div class="flex w-full flex-col 2xl:flex-row 2xl:gap-3">
                        <mat-form-field appearance="outline" class="w-full 2xl:w-1/2">
                            <mat-label>Street</mat-label>
                            <input matInput placeholder="Street" formControlName="street" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-full 2xl:w-1/2">
                            <mat-label>PO box</mat-label>
                            <input matInput placeholder="PO box" formControlName="poBox" />
                        </mat-form-field>
                    </div>
                    <div class="flex w-full flex-col 2xl:flex-row 2xl:gap-3">
                        <mat-form-field appearance="outline" class="w-full 2xl:w-1/2">
                            <mat-label>City</mat-label>
                            <input matInput placeholder="City" formControlName="city" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-full 2xl:w-1/2">
                            <mat-label>State</mat-label>
                            <input matInput placeholder="State" formControlName="state" />
                        </mat-form-field>
                    </div>
                    <div class="flex w-full flex-col 2xl:flex-row 2xl:gap-3">
                        <mat-form-field appearance="outline" class="w-full 2xl:w-1/2">
                            <mat-label>Postal code</mat-label>
                            <input matInput placeholder="Postal code" formControlName="postalCode" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="w-full 2xl:w-1/2">
                            <mat-label>Country</mat-label>
                            <input matInput placeholder="Country" formControlName="country" />
                        </mat-form-field>
                    </div>
                </div>
                <button mat-icon-button class="!absolute -right-2 xl:-right-12" (click)="onDeleteAddress(i)">
                    <mat-icon class="w-6 min-w-6">close</mat-icon>
                </button>
            </div>
        </div>
    </div>
}
