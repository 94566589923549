import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormArray, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'p-ui-address-form',
    templateUrl: './address-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AddressFormComponent,
            multi: true,
        },
    ],
    imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, MatButtonModule, MatSelectModule],
})
export class AddressFormComponent implements ControlValueAccessor, OnInit {
    readonly #cdr = inject(ChangeDetectorRef);

    private readonly destroyRef: DestroyRef = inject(DestroyRef);
    @Input() public addressFormArray!: FormArray;
    @Input() public addressOptions?: string[];

    get addressFormGroups(): FormGroup[] {
        return this.addressFormArray.controls as FormGroup[];
    }

    public ngOnInit() {
        this.addressFormArray.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.#cdr.markForCheck();
        });
    }

    public writeValue(fn: any): void {
        //
    }

    public registerOnChange(fn: any): void {
        //
    }

    public registerOnTouched(fn: any): void {
        //
    }

    public onDeleteAddress(index: number): void {
        this.addressFormArray.removeAt(index);
        this.addressFormArray.markAsDirty();
    }
}
