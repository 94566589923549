import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, type, withComputed, withMethods, withState } from '@ngrx/signals';
import { entityConfig, removeAllEntities, setAllEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Observable, pipe, Subject, switchMap, tap } from 'rxjs';

import { LocationEntity, Page } from '@portal/shared/utils';

import { LocationsResourceController } from '../../../+api';

interface State {
    loadingEntities: boolean;
    locationsLoaded: boolean;
}

const initialState: State = {
    loadingEntities: false,
    locationsLoaded: false,
};

const config = entityConfig({
    entity: type<LocationEntity>(),
    collection: 'locations',
    selectId: (model: LocationEntity) => model.locationId,
});

export const LocationsStore = signalStore(
    {},
    withState<State>(initialState),
    withEntities(config),
    withComputed((store) => ({
        hasLocations: computed(() => store.locationsEntities().length > 0),
    })),
    withMethods((store, loadEntitiesSuccessSource = new Subject<LocationEntity[]>(), loadEntitiesFailedSource = new Subject<void>()) => ({
        _loadEntitiesSuccess: (data: LocationEntity[]): void => {
            patchState(store, setAllEntities(data, config), { locationsLoaded: true, loadingEntities: false });
            loadEntitiesSuccessSource.next(data);
        },
        onLoadEntitiesSuccess: (): Observable<LocationEntity[]> => loadEntitiesSuccessSource.asObservable(),
        _loadEntitiesFailed: (data: string): void => {
            patchState(store, removeAllEntities(config), { locationsLoaded: true, loadingEntities: false });
            loadEntitiesFailedSource.next();
        },
        onLoadEntitiesFailed: (): Observable<void> => loadEntitiesFailedSource.asObservable(),
    })),
    withMethods((store, locationController = inject(LocationsResourceController)) => ({
        loadAll: rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loadingEntities: true })),
                switchMap(() =>
                    locationController.getLocations().pipe(
                        tapResponse({
                            next: (data: Page<LocationEntity>) => store._loadEntitiesSuccess(data.content),
                            error: () => store._loadEntitiesFailed('Error loading locations'),
                        })
                    )
                )
            )
        ),
    })),
    withMethods((store) => ({
        initialize: () => store.loadAll(),
    }))
);
