<h2 mat-dialog-title>Change role</h2>
<mat-dialog-content>
    <form [formGroup]="changeRoleForm" novalidate class="flex w-96 justify-center pt-1">
        <mat-form-field appearance="outline" class="w-full">
            <mat-label>Role</mat-label>
            <mat-select formControlName="roles">
                @for (role of roles; track role.value) {
                    <mat-option [value]="role.value">
                        {{ role.label }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mb-4 mr-4 flex w-full justify-end">
        <button mat-button mat-dialog-close>Discard</button>
        <button mat-raised-button class="primary" [disabled]="changeRoleForm.pristine" (click)="onChangeRole()">Save</button>
    </div>
</mat-dialog-actions>
