import { ChangeDetectionStrategy, Component, effect, inject, OnInit, viewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { ContainerContentDirective, ContainerPanelDirective, ContainerShellApiDirective, ContainerShellComponent } from '@portal/shared/ui';

import { AuthenticatedLayoutApi } from './authenticated-layout.api';
import { AuthenticatedLayoutBloc } from './authenticated-layout.bloc';

@Component({
    imports: [ContainerShellComponent, ContainerShellApiDirective, RouterOutlet, ContainerContentDirective, ContainerPanelDirective],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <p-ui-container-shell api #api="api">
            <router-outlet *pUiContainerContent="'content'" />
            <ng-container *pUiContainerPanel="'drawer'; mode: 'over'" />
        </p-ui-container-shell>
    `,
    providers: [AuthenticatedLayoutBloc, AuthenticatedLayoutApi],
})
export class AuthenticatedLayoutComponent implements OnInit {
    private readonly $containerShellApi = viewChild.required(ContainerShellApiDirective);

    private readonly _bloc = inject(AuthenticatedLayoutBloc);

    public $templateRef = this._bloc.$templateRef;

    public ngOnInit(): void {
        this._bloc.initialize();
    }

    private onOpenTemplate = effect(() => {
        const templateRef = this.$templateRef();
        const containerShellApi = this.$containerShellApi();

        if (containerShellApi) {
            if (templateRef !== null) {
                containerShellApi.setDrawer(templateRef);
                containerShellApi.openDrawer();
            } else {
                containerShellApi.closeDrawer();
            }
        }
    });
}
