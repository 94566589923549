import {
    CloseScrollStrategy,
    ConnectionPositionPair,
    FlexibleConnectedPositionStrategy,
    FlexibleConnectedPositionStrategyOrigin,
    Overlay,
    OverlayConfig,
    OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, inject, isDevMode, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { IsNotUndefined } from '@portal/shared/utils';

@Directive()
export abstract class BaseOverlay<TComponent = null> implements OnDestroy {
    public config: OverlayConfig | undefined;
    public overlayRef: OverlayRef | undefined;
    public ignoreViewportMargin: boolean | undefined;

    protected connectionElement: FlexibleConnectedPositionStrategyOrigin | undefined;
    protected overlayPosition: FlexibleConnectedPositionStrategy | undefined;
    protected positions: ConnectionPositionPair[] = [];
    protected isOpen: boolean = false;
    protected templateToAttach: TemplateRef<unknown> | undefined;
    protected ComponentToAttach: ComponentType<TComponent> | undefined;
    protected componentAttachment: ComponentRef<TComponent> | undefined;

    protected readonly elementRef: ElementRef = inject(ElementRef);
    protected readonly overlay: Overlay = inject(Overlay);
    protected readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
    protected readonly baseOverlayClass: string[] = [];

    private readonly viewportMargin: number = 4;

    public get isVisible(): boolean {
        return this.isOpen;
    }

    public ngOnDestroy(): void {
        if (this.isOpen) {
            this.hide();
        }
    }

    protected show(): void {
        if (!this.ComponentToAttach && !this.templateToAttach) {
            if (isDevMode()) {
                throw new Error('Nothing to attach.');
            }
        }
        this.isOpen = true;
        this.showOverlay();
    }

    protected hide(): void {
        this.isOpen = false;
        this.componentAttachment?.destroy();
        this.overlayRef?.detach();
        this.overlayRef?.dispose();
    }

    private showOverlay(): void {
        let portal: ComponentPortal<TComponent> | TemplatePortal;
        if (IsNotUndefined(this.ComponentToAttach)) {
            portal = new ComponentPortal(this.ComponentToAttach, this.viewContainerRef);
            this.createOverlay(portal);
        } else if (IsNotUndefined(this.templateToAttach)) {
            portal = new TemplatePortal(this.templateToAttach, this.viewContainerRef);
            this.createOverlay(portal);
        } else {
            if (isDevMode()) {
                throw new Error('Nothing to attach.');
            }
        }
    }

    private createOverlay(portal: ComponentPortal<TComponent> | TemplatePortal): void {
        const isCloseStrategy: boolean = this.config?.scrollStrategy instanceof CloseScrollStrategy;
        this.overlayPosition = this.getOverlayPosition(this.positions);

        this.overlayRef = this.overlay.create({
            width: this.config?.width,
            height: this.config?.height,
            maxWidth: this.config?.maxWidth,
            maxHeight: this.config?.maxHeight,
            minWidth: this.config?.minWidth,
            minHeight: this.config?.minHeight,
            scrollStrategy: isCloseStrategy ? this.overlay.scrollStrategies.close() : this.overlay.scrollStrategies.reposition(),
            positionStrategy: this.overlayPosition,
            panelClass: this.getPanelClasses(),
        });

        if (this.ComponentToAttach) {
            this.componentAttachment = this.overlayRef.attach(portal);
        } else {
            this.overlayRef.attach(portal);
        }
    }

    private getPanelClasses(): string[] {
        if (this.config?.panelClass) {
            return Array.isArray(this.config.panelClass)
                ? [...this.config.panelClass, ...this.baseOverlayClass]
                : [this.config.panelClass, ...this.baseOverlayClass];
        } else {
            return this.baseOverlayClass;
        }
    }

    protected getOverlayPosition(positions: ConnectionPositionPair[]): FlexibleConnectedPositionStrategy {
        return this.overlay
            .position()
            .flexibleConnectedTo(this.connectionElement ?? this.elementRef)
            .withPositions(positions)
            .withFlexibleDimensions(false)
            .withViewportMargin(this.ignoreViewportMargin ? 0 : this.viewportMargin);
    }

    public updatePosition(): void {
        if (IsNotUndefined(this.overlayRef)) {
            this.overlayPosition = this.getOverlayPosition(this.positions);
            this.overlayRef.updatePositionStrategy(this.overlayPosition);
        }
    }
}
