import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, forwardRef, input, signal, WritableSignal } from '@angular/core';

import { PortalPlacement, PortalPositionsUtils } from '@portal/shared/ui';

import { HostItem } from './host-item';
import { PriorityHostItemComponent } from './priority-host-item.component';
import { AccountMenuComponent } from '../../account-menu/account-menu.component';

@Component({
    imports: [PriorityHostItemComponent, CdkOverlayOrigin, CdkConnectedOverlay, AccountMenuComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div (click)="onToggle()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            <p-ft-priority-host-item [isActiveBackground]="false" [isActiveIcon]="$isOpen()" [icon]="$icon()" [title]="$title()" />
        </div>

        <ng-template
            cdkConnectedOverlay
            (backdropClick)="onToggle()"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayOpen]="$isOpen()"
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayPositions]="positions"
            cdkConnectedOverlayBackdropClass=""
        >
            <div class="bg-ds-light-500 flex items-center gap-3 rounded-md p-5 shadow">
                <p-ft-account-menu (close)="onToggle()" />
            </div>
        </ng-template>
    `,
    providers: [
        {
            provide: HostItem,
            useValue: forwardRef(() => AccountHostItemComponent),
        },
    ],
})
export class AccountHostItemComponent {
    protected readonly $icon = input.required<string>({ alias: 'icon' });
    protected readonly $title = input<string | null>(null, { alias: 'title' });

    readonly #isOpenSource: WritableSignal<boolean> = signal<boolean>(false);
    protected $isOpen = this.#isOpenSource.asReadonly();

    protected readonly positions: ConnectionPositionPair[] = PortalPositionsUtils.getPositions(PortalPlacement.RightToLeft);

    protected onToggle() {
        this.#isOpenSource.update((state) => !state);
    }
}
