<h2 mat-dialog-title class="ml-4 mt-4 font-bold">Custom label name</h2>
<mat-dialog-content>
    <form [formGroup]="createCustomLabel" novalidate>
        <mat-form-field class="w-full pt-2 sm:w-96" appearance="outline">
            <mat-label>Label name</mat-label>
            <input matInput #message [maxlength]="LABEL_NAME_MAX_LENGTH" formControlName="labelName" />
            <mat-hint align="start">Max 30 characters</mat-hint>
            <mat-hint align="end">{{ message.value.length }} / 30</mat-hint>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mb-4 mr-4 flex w-full justify-end">
        <button mat-button type="button" mat-dialog-close>Cancel</button>
        <button mat-raised-button class="primary" (click)="onCreate()">Ok</button>
    </div>
</mat-dialog-actions>
