import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SettingsResource, SettingsType } from '@portal/shared/utils';

@Injectable()
export class SettingsResourceController<T> {
    private readonly _resource: SettingsResource<T> = inject(SettingsResource);

    public getSettings(settingsType: SettingsType): Observable<T | null> {
        return this._resource.getSettings(settingsType);
    }

    public updateSettings(settingsType: SettingsType, data: T): Observable<T> {
        return this._resource.updateSettings(settingsType, data);
    }
}
