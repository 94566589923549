import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { LogoTypes, minLogoSize } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-logo',
    templateUrl: './logo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule],
})
export class LogoComponent {
    @Input() public type: LogoTypes = LogoTypes.Colored;
    @Input() public height: number = minLogoSize;
    @Input() public width: number = minLogoSize;
    protected readonly LogoTypes = LogoTypes;
}
