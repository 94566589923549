import { ChangeDetectionStrategy, Component, forwardRef, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { HostItem } from './host-item';
import { PriorityHostItemComponent } from './priority-host-item.component';

@Component({
    imports: [PriorityHostItemComponent, RouterLinkActive, RouterLink],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <a [routerLink]="$absolutePath()" #rla="routerLinkActive" routerLinkActive>
            <p-ft-priority-host-item
                [isActiveBackground]="rla.isActive"
                [isActiveIcon]="rla.isActive"
                [icon]="$icon()"
                [title]="$title()"
                [iconClass]="$isIconOutlined()"
            />
        </a>
    `,
    providers: [
        {
            provide: HostItem,
            useValue: forwardRef(() => NavigationHostItemComponent),
        },
    ],
})
export class NavigationHostItemComponent {
    public readonly $absolutePath = input.required<string>({ alias: 'absolutePath' });
    public readonly $icon = input.required<string>({ alias: 'icon' });
    public readonly $title = input<string | null>(null, { alias: 'title' });
    public readonly $isIconOutlined = input<boolean>(false, { alias: 'isIconOutlined' });
}
