import { inject, Injectable, TemplateRef } from '@angular/core';

import { AuthenticatedLayoutBloc, CommonPortal } from './authenticated-layout.bloc';

@Injectable()
export class AuthenticatedLayoutApi {
    private readonly _bloc = inject(AuthenticatedLayoutBloc);

    public setTemplate(templateRef: TemplateRef<any> | null) {
        this._bloc.setTemplate(templateRef);
    }

    public setPortal(portal: CommonPortal | null) {
        this._bloc.setPortal(portal);
    }
}
