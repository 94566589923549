import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    HostBinding,
    inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LocalizationSource } from '@portal/shared/utils';

import { InputIntlTelDirective } from './input-intl-tel.directive';
import { convertCountryToIntlTelInputCountryCode, IntlTelInputType, SelectedCountryData } from './int-tel.type';

@Component({
    selector: 'p-ui-input-intl-tel',
    templateUrl: './input-intl-tel.component.html',
    styles: [
        `
            :host {
                @apply block;

                ::ng-deep .iti {
                    width: 100%;
                }

                ::ng-deep .iti__search-input {
                    padding-left: 8px;
                }
            }

            input {
                @apply border-none bg-transparent outline-none;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ReactiveFormsModule, InputIntlTelDirective],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: InputIntlTelComponent,
        },
    ],
})
export class InputIntlTelComponent implements OnInit, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {
    public readonly ngControl = inject(NgControl, { optional: true, self: true });
    readonly #elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
    readonly #localizationSource = inject(LocalizationSource);

    public readonly $defaultCountry = computed(() => convertCountryToIntlTelInputCountryCode(this.#localizationSource.$country()));

    /* eslint-disable @typescript-eslint/class-literal-property-style */
    public static nextId: number = 0;

    @HostBinding()
    public id: string = `ui-input-intl-tel-${InputIntlTelComponent.nextId++}`;

    @HostBinding('class.floating')
    public get shouldLabelFloat(): boolean {
        return this.focused || !this.empty;
    }

    @Input({ transform: booleanAttribute })
    public readOnly: boolean = false;

    @Input()
    public controlType!: string;

    @Input()
    public userAriaDescribedBy!: string;

    @Input()
    public get placeholder(): string {
        return this._placeholder;
    }

    public set placeholder(text: string) {
        this._placeholder = text;
        this.stateChangesSource.next();
    }

    @Input()
    public get required(): boolean {
        return this._required;
    }

    public set required(req: boolean) {
        this._required = coerceBooleanProperty(req);
        this.stateChangesSource.next();
    }

    @Input()
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
        // this._disabled ? this.parts.disable() : this.parts.enable();
        this.stateChangesSource.next();
    }

    public set value(value: string | null) {
        this._value = value ?? '';
        this.stateChangesSource.next();
    }

    public get value(): string {
        return this._value;
    }

    public get empty(): boolean {
        // const n = this.parts.value;
        // return !n.area && !n.exchange && !n.subscriber;
        return false;
    }

    public get errorState(): boolean {
        return !!this.ngControl?.invalid && this.touched;
    }

    public onChange!: (value: string) => void;
    public onTouched!: () => void;

    private readonly stateChangesSource: Subject<void> = new Subject<void>();
    public readonly stateChanges = this.stateChangesSource.asObservable();
    public focused: boolean = false;
    public touched: boolean = false;
    public phoneControl: UntypedFormControl = new UntypedFormControl();

    private readonly destroySource: Subject<void> = new Subject<void>();

    private _value!: string;
    private _placeholder!: string;
    private _required: boolean = false;
    private _disabled: boolean = false;
    private _intlTelInstance!: IntlTelInputType;

    constructor() {
        if (this.ngControl !== null) {
            this.ngControl.valueAccessor = this;
        }
    }

    public ngOnInit(): void {
        this.phoneControl.valueChanges.pipe(takeUntil(this.destroySource)).subscribe((value: string) => {
            this.updateValue(value);
        });
    }

    public ngOnDestroy(): void {
        this.stateChangesSource.complete();
    }

    public onFocusIn(event: FocusEvent): void {
        if (!this.focused) {
            this.focused = true;
            this.stateChangesSource.next();
        }
    }

    public onFocusOut(event: FocusEvent): void {
        if (!this.#elementRef.nativeElement.contains(event.relatedTarget as Element)) {
            this.touched = true;
            this.focused = false;
            this.onTouched();
            this.stateChangesSource.next();
        }
    }

    public onContainerClick(event: MouseEvent): void {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            // (this.inputRef.nativeElement as HTMLInputElement).focus();
        }
    }

    public onIntlTelInstanceReady(instance: IntlTelInputType): void {
        this._intlTelInstance = instance;
    }

    public setDescribedByIds(ids: string[]): void {
        // const controlElement = this._elementRef.nativeElement
        //     .querySelector('.example-tel-input-container')!;
        // controlElement.setAttribute('aria-describedby', ids.join(' '));
    }

    public registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: string): void {
        this.value = value;
        const parsedValue: string = value ? (value.startsWith('+') ? value : `+${value}`) : '';
        this.phoneControl.setValue(parsedValue);
        if (this._intlTelInstance) {
            this._intlTelInstance.setNumber(parsedValue);
        }
    }

    private updateValue(phone: string): void {
        if (!phone || phone.trim() === '') {
            this.onChange('');
            return;
        }

        const dialData: SelectedCountryData = this._intlTelInstance?.getSelectedCountryData();
        const dialCode: string = dialData.dialCode ?? '';

        const result: string = `+${dialCode}-${phone}`;
        this.value = result;
        this.onChange(result);
    }
}
