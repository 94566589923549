import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { concatMap, exhaustMap, merge, pipe, Subject, takeUntil, tap } from 'rxjs';

import { DateFormatUtils, LocalizationSource, LocalizationUtils, SettingsType, withCrudEvents } from '@portal/shared/utils';

import { SnackbarService } from '@portal/core/utils';

import { SettingsResourceController } from '../../../+api';

interface State {
    settings: LocalizationUtils.Settings;
}

const initialState: State = {
    settings: LocalizationUtils.DEFAULT_SETTINGS,
};

export const LocalizationStore = signalStore(
    {},
    withState<State>(initialState),
    withComputed((state) => ({
        datePipeFormat: computed(() => DateFormatUtils.getFormat(state.settings()?.dateFormat ?? null)),
    })),
    withCrudEvents<LocalizationUtils.Settings>(),
    withMethods(
        (
            store,
            settingsController: SettingsResourceController<LocalizationUtils.Settings> = inject(SettingsResourceController),
            snackbar = inject(SnackbarService)
        ) => ({
            read: rxMethod<void>(
                pipe(
                    tap(() => patchState(store, { readingEntity: true })),
                    exhaustMap(() =>
                        settingsController.getSettings(SettingsType.LOCALIZATION).pipe(
                            tapResponse({
                                next: (settings: LocalizationUtils.Settings | null) => {
                                    store._readEntitySuccess(settings ?? LocalizationUtils.DEFAULT_SETTINGS);
                                    patchState(store, { settings: settings ?? LocalizationUtils.DEFAULT_SETTINGS });
                                },
                                error: (e: HttpErrorResponse) => {
                                    store._readEntityFailed(e.error?.message ?? null);
                                },
                                finalize: () => patchState(store, { readingEntity: false }),
                            })
                        )
                    )
                )
            ),
            update: rxMethod<LocalizationUtils.Settings>(
                pipe(
                    tap(() => patchState(store, { updatingEntity: true })),
                    concatMap((settings) =>
                        settingsController.updateSettings(SettingsType.LOCALIZATION, settings).pipe(
                            tapResponse({
                                next: () => {
                                    store._updateEntitySuccess(settings);
                                    patchState(store, { settings });
                                    snackbar.showSnackbar('Changes saved');
                                },
                                error: (e: HttpErrorResponse) => {
                                    store._updateEntityFailed(e.error?.message ?? null);
                                },
                                finalize: () => patchState(store, { updatingEntity: false }),
                            })
                        )
                    )
                )
            ),
            onRead: rxMethod<void>(pipe()),
        })
    ),
    withMethods((store) => ({
        initialize: () => {
            store.read();
        },
    })),
    withHooks((state, destroy = new Subject<void>(), localizationSource = inject(LocalizationSource)) => ({
        onInit: () => {
            merge(state.onReadEntitySuccess(), state.onUpdateEntitySuccess())
                .pipe(takeUntil(destroy))
                .subscribe((settings: LocalizationUtils.Settings) => {
                    const defaultSettings = LocalizationUtils.DEFAULT_SETTINGS;

                    localizationSource.setDateFormat(settings.dateFormat ?? defaultSettings.dateFormat);
                    localizationSource.setTimeFormat(settings.timeFormat ?? defaultSettings.timeFormat);
                    localizationSource.setCountry(settings.country ?? defaultSettings.country);
                    localizationSource.setCurrency(settings.currency ?? defaultSettings.currency);
                    localizationSource.setCurrencyFormat(settings.currencyFormat ?? defaultSettings.currencyFormat);
                    localizationSource.setCurrencySymbol(settings.currencySymbol ?? defaultSettings.currencySymbol);
                    localizationSource.setNegativeFormat(settings.negativeFormat ?? defaultSettings.negativeFormat);
                    localizationSource.setSymbolBackward(settings.isSymbolBackward ?? defaultSettings.isSymbolBackward);
                });
        },
        onDestroy: () => {
            destroy.next();
            destroy.complete();
        },
    }))
);
