import { ChangeDetectionStrategy, Component, computed, DestroyRef, forwardRef, inject, OnInit, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect, MatSelectChange } from '@angular/material/select';

import { ContentLoaderSnippet } from '@common/ui';

import { CountriesStore } from '@portal/shared/data-access';
import {
    ADD_ADDRESS_DIALOG,
    AddAddressDialogConfig,
    AddAddressDialogModel,
    AddressValidations,
    ContactAddressDto,
    CountryItemDto,
    CreateContactAddress,
    FormUtils,
    LocalizationSource,
    LocalizationUtils,
    PROVINCE_COUNTRIES,
    STATE_COUNTRIES,
    ValidationExpressions,
} from '@portal/shared/utils';

@Component({
    templateUrl: './add-address-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButton,
        MatDialogActions,
        MatDialogContent,
        MatDialogTitle,
        MatFormField,
        MatLabel,
        MatInput,
        MatOption,
        MatSelect,
        ReactiveFormsModule,
        ContentLoaderSnippet,
    ],
    providers: [
        {
            provide: ADD_ADDRESS_DIALOG,
            useExisting: forwardRef(() => AddAddressDialogComponent),
        },
    ],
})
export class AddAddressDialogComponent implements OnInit, AddAddressDialogModel {
    private fb = inject(FormBuilder);
    private dialog = inject(MatDialog);

    public readonly data = inject<{
        label: string;
        address?: ContactAddressDto;
        isCountryDisabled: boolean;
    }>(MAT_DIALOG_DATA);
    readonly #dialogRef = inject<MatDialogRef<AddAddressDialogComponent>>(MatDialogRef);

    readonly #localizationSource = inject(LocalizationSource);
    private countriesStore = inject(CountriesStore);

    public readonly $countries = this.countriesStore.countries;
    public readonly $country = this.countriesStore.country;
    public readonly $countriesLoading = this.countriesStore.countriesLoading;
    public readonly $countryRegions = computed(() => this.$country()?.regions ?? []);

    public readonly addAddress = output<ContactAddressDto>();

    private readonly destroyRef: DestroyRef = inject(DestroyRef);

    public readonly postalCodeError: string = 'invalidPostalCode';

    public readonly STREET_MAX_LENGTH = AddressValidations.STREET_MAX_LENGTH;
    public readonly PO_BOX_MAX_LENGTH = AddressValidations.PO_BOX_MAX_LENGTH;
    public readonly CITY_MAX_LENGTH = AddressValidations.CITY_MAX_LENGTH;
    public readonly POSTAL_CODE_MAX_LENGTH = AddressValidations.POSTAL_CODE_MAX_LENGTH;

    private $stateLabelSource = signal<string>('State/Province');
    private $postalCodeLabelSource = signal<string>('Zip/Postal Code');

    public $stateLabel = this.$stateLabelSource.asReadonly();
    public $postalCodeLabel = this.$postalCodeLabelSource.asReadonly();

    public addAddressForm = this.fb.group(
        {
            country: new FormControl<string | null>(null, Validators.required),
            street: new FormControl<string | null>(null, Validators.maxLength(AddressValidations.STREET_MAX_LENGTH)),
            poBox: new FormControl<string | null>(null, Validators.maxLength(AddressValidations.PO_BOX_MAX_LENGTH)),
            city: new FormControl<string | null>(null, Validators.maxLength(AddressValidations.CITY_MAX_LENGTH)),
            state: new FormControl<string | null>(null),
            postalCode: new FormControl<string | null>(null, Validators.maxLength(AddressValidations.POSTAL_CODE_MAX_LENGTH)),
            label: new FormControl<string | null>(this.data.label ?? null),
        },
        {
            validators: [AddressFormValidators.postalIndexReferenceValidator('country', 'postalCode', this.postalCodeError)],
        }
    );

    constructor() {
        const dialogRef = this.#dialogRef;

        dialogRef.disableClose = true;
    }

    public open(config: AddAddressDialogConfig): MatDialogRef<AddAddressDialogModel> {
        const dialogRef = this.dialog.open(AddAddressDialogComponent, {
            data: {
                label: config.label,
                address: config.address,
                isCountryDisabled: config.isCountryDisabled,
            },
            viewContainerRef: config.viewContainerRef,
        });

        return dialogRef;
    }

    public close(): void {
        this.#dialogRef.close();
    }

    public ngOnInit(): void {
        this.countriesStore.read();

        this.#dialogRef
            .backdropClick()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => {
                this.onCancel();
            });

        if (this.data.isCountryDisabled) {
            this.addAddressForm.controls.country.disable();
        }

        this.countriesStore
            .onReadCountriesSuccess()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((countries) => {
                const initialCountry = this.#localizationSource.$country() ?? null;
                const initialAddress = this.data.address;

                if (initialCountry) {
                    this.setCountryBasedLabels(initialCountry);

                    this.addAddressForm.patchValue({
                        country: initialCountry,
                    });
                }

                if (initialAddress) {
                    this.addAddressForm.patchValue({
                        city: initialAddress.city,
                        country: initialCountry,
                        label: initialAddress.label,
                        poBox: initialAddress.poBox,
                        postalCode: initialAddress.postalCode,
                        state: initialAddress.state,
                        street: initialAddress.street,
                    });
                }
            });
    }

    public onCountrySelectionChange(selectChange: MatSelectChange) {
        const country = selectChange.value as CountryItemDto;

        this.addAddressForm.controls.state.reset();

        this.countriesStore.readByCode(country.countryCode);
        this.setCountryBasedLabels(country.countryName);
    }

    public setCountryBasedLabels(country: string) {
        this.$stateLabelSource.set(this.getCountryBasedValue(country, 'Province', 'State', 'State/Province'));
        this.$postalCodeLabelSource.set(this.getCountryBasedValue(country, 'Postal Code', 'Zip', 'Zip/Postal Code'));
    }

    private getCountryBasedValue(country: string, province: string, states: string, defaultValue: string): string {
        const isProvinceBased = AddressFormValidators.isProvinceBased(country as LocalizationUtils.CountryEnum);
        const isStateBased = AddressFormValidators.isStateBased(country as LocalizationUtils.CountryEnum);

        let label: string;

        if (isProvinceBased) {
            label = province;
        } else if (isStateBased) {
            label = states;
        } else {
            label = defaultValue;
        }

        return label;
    }

    public onAddAddress(): void {
        if (this.addAddressForm.valid) {
            const formValue = this.addAddressForm.getRawValue();

            const address: CreateContactAddress = {
                street: formValue.street,
                poBox: formValue.poBox,
                city: formValue.city,
                state: formValue.state,
                postalCode: formValue.postalCode,
                country: formValue.country,
                label: formValue.label,
                primary: null,
            };

            this.addAddress.emit(address);
        }
    }

    public onCancel(): void {
        this.#dialogRef.close();
    }
}

namespace AddressFormValidators {
    export function isProvinceBased(country: LocalizationUtils.CountryEnum): boolean {
        return PROVINCE_COUNTRIES.has(country);
    }

    export function isStateBased(country: LocalizationUtils.CountryEnum): boolean {
        return STATE_COUNTRIES.has(country);
    }

    export function isCanadaCountry(country: string): boolean {
        return LocalizationUtils.CountryEnum.Canada === country;
    }

    export function isUsaCountry(country: string): boolean {
        return LocalizationUtils.CountryEnum['United States'] === country;
    }

    export function isStubCountry(country: string): boolean {
        return true;
    }

    export function getPostalIndexReference(): void {
        // /
    }

    export function postalIndexReferenceValidator(
        countryControlName: string,
        postalCodeControlName: string,
        errorName: string
    ): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const formGroup = control as FormGroup;
            const error: object = { [errorName]: true };
            const checks: Array<[(country: string) => boolean, RegExp]> = [
                [isCanadaCountry, ValidationExpressions.CANADIAN_POSTAL_CODE_PATTERN],
                [isUsaCountry, ValidationExpressions.USA_ZIP_CODE_PATTERN],
                [isStubCountry, ValidationExpressions.STUB_PATTERN],
            ];

            checks.forEach(([isCountry, countryRegExp]) => {
                if (isCountry(formGroup.controls[countryControlName].value)) {
                    const isValid: boolean = countryRegExp.test(formGroup.controls[postalCodeControlName].value);
                    if (isValid) {
                        FormUtils.Validators.removeErrors(formGroup.controls[postalCodeControlName], errorName);
                    } else {
                        formGroup.controls[postalCodeControlName].setErrors(error);
                    }
                }
            });

            return null;
        };
    }
}
