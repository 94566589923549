<pdf-shy-button
  primaryToolbarId="primaryZoomOut"
  class="zoomOut"
  title="Zoom Out"
  l10nId="pdfjs-zoom-out-button"
  l10nLabel="pdfjs-zoom-out-button-label"
  eventBusName="zoomout"
  [cssClass]="showZoomButtons | responsiveCSSClass : 'always-visible'"
  [order]="1500"
  image="<svg width='24px' height='24px' viewBox='0 0 24 24'><path fill='currentColor' d='M19,13H5V11H19V13Z' /></svg>"
  [disabled]="disabled"
>
</pdf-shy-button>
