import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';

import { PortalPlacement } from '../overlay/constants/portal-placement';
import { PortalTemplateProps } from '../overlay/interfaces/portal-template-props';

@Component({
    imports: [NgTemplateOutlet],
    // selector: 'p-ui-portal',
    templateUrl: './portal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PUIPortalComponent implements OnChanges {
    @Input() public view?: TemplateRef<PortalTemplateProps>;
    @Input() public text?: string;
    @Input() public placement?: PortalPlacement;
    @Input() public templateProps: unknown;
    @Input() public closeFn?: VoidFunction;

    public ctx!: PortalTemplateProps;

    public ngOnChanges(changes: SimpleChanges) {
        if (changes['closeFn'] || changes['templateProps']) {
            this.ctx = { close: this.closeFn, props: this.templateProps };
        }
    }
}
