import { Provider } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { AddAddressDialogComponent } from '@portal/shared/features';
import { ADD_ADDRESS_DIALOG } from '@portal/shared/utils';

export const provideAddAddressDialog = (): Provider[] => [
    {
        provide: MatDialogRef,
        useValue: {},
    },
    {
        provide: MAT_DIALOG_DATA,
        useValue: 'Mat Dialog Data Injection Token',
    },
    {
        provide: ADD_ADDRESS_DIALOG,
        useClass: AddAddressDialogComponent,
    },
];
