import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { TeamMemberTextDto } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-pdf-viewer',
    imports: [NgxExtendedPdfViewerModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './pdf-viewer.component.html',
})
export class PDFViewerComponent {
    public readonly $fileView = input.required<TeamMemberTextDto>({ alias: 'fileView' });
}
