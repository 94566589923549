import { ConnectionPositionPair } from '@angular/cdk/overlay';

import { PortalPlacement } from './portal-placement';
import { CommonPortalPositionsMap } from './portal-positions';

export namespace PortalPositionsUtils {
    export function getPositions(placement: PortalPlacement): ConnectionPositionPair[] {
        if (!CommonPortalPositionsMap.has(placement)) {
            throw new Error(`No Portal Positions found for placement "${placement}"`);
        }

        return CommonPortalPositionsMap.get(placement)!;
    }
}
