import { Provider } from '@angular/core';

import {
    AuthenticationResource,
    ContactGroupsResource,
    ContactResource,
    DisciplinesResource,
    EmployeeResource,
    LocationResource,
    SettingsResource,
    SupplierResource,
    TeamMemberResource,
    UsersResource,
} from '@portal/shared/utils';

import { EmployeesResourceController, SuppliersResourceController } from './accounting-api';
import { ContactGroupsResourceController, ContactResourceController } from './contact-api';
import { CountriesResource, CountriesResourceController } from './countries-api';
import { LocationsResourceController, SettingsResourceController } from './location-api';
import { DisciplinesResourceController, TeamMembersResourceController } from './team-api';
import { AuthenticationResourceController, UsersResourceController } from './user-api';

export const SharedDataAccessApiProviders: Provider = [
    AuthenticationResource,
    AuthenticationResourceController,
    ContactGroupsResource,
    ContactGroupsResourceController,
    ContactResource,
    ContactResourceController,
    DisciplinesResource,
    DisciplinesResourceController,
    EmployeeResource,
    EmployeesResourceController,
    LocationResource,
    LocationsResourceController,
    SettingsResource,
    SettingsResourceController,
    SupplierResource,
    SuppliersResourceController,
    TeamMemberResource,
    TeamMembersResourceController,
    UsersResource,
    UsersResourceController,
    CountriesResource,
    CountriesResourceController,
];
