import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Provider } from '@angular/core';
import { filter, finalize, Observable, ReplaySubject, tap } from 'rxjs';

import { AuthenticationResourceController } from '@portal/shared/data-access';
import { IsNotNull, JwtResponseDto, REAUTH_MANAGER, ReauthManager } from '@portal/shared/utils';

export function provideReauthManager(): Provider {
    return [
        {
            provide: REAUTH_MANAGER,
            deps: [AuthenticationResourceController],
            useFactory: (
                controller: AuthenticationResourceController,
                $skipUntilReauthSource: ReplaySubject<JwtResponseDto> | null = null
            ): ReauthManager => ({
                isSkipRefreshReq: (req: HttpRequest<unknown>): boolean => req.url.includes('refresh-token'),
                isRefreshTokenInvalid: (err: HttpErrorResponse) => {
                    return (
                        err.error === 'Refresh token is invalid.' ||
                        // TODO error should not be occurred with such message
                        err.error === 'Refresh token is expired.'
                    );
                },
                skipUntilReauth: (): Observable<JwtResponseDto> | null =>
                    IsNotNull($skipUntilReauthSource) ? $skipUntilReauthSource.asObservable() : null,
                refreshToken: (refreshToken: string): Observable<any> => {
                    if ($skipUntilReauthSource) {
                        return $skipUntilReauthSource.pipe(filter(IsNotNull));
                    } else {
                        $skipUntilReauthSource = new ReplaySubject<JwtResponseDto>(1);
                        return controller.refreshToken(refreshToken).pipe(
                            tap((jwtResponse: JwtResponseDto) => {
                                if (IsNotNull($skipUntilReauthSource)) {
                                    $skipUntilReauthSource.next(jwtResponse);
                                }
                            }),
                            finalize(() => {
                                $skipUntilReauthSource = null;
                            })
                        );
                    }
                },
                logout: () => {
                    $skipUntilReauthSource = null;

                    return controller.logout();
                },
            }),
        },
    ];
}
