import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import {
    AttachementDto,
    AttachementUploading,
    FileType,
    FileTypePipe,
    FileViewMode,
    getFileType,
    HasItemsPipe,
    ViewFileData,
} from '@portal/shared/utils';

import { DeleteFileDialogComponent } from '../delete-file-dialog/delete-file-dialog.component';

@Component({
    selector: 'p-ui-view-attachements',
    imports: [DatePipe, MatIconModule, MatButtonModule, MatProgressSpinnerModule, FileTypePipe, MatMenuModule, HasItemsPipe],
    templateUrl: './view-attachements.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: `
        .file-icon {
            font-variation-settings:
                'FILL' 0,
                'wght' 100,
                'GRAD' 200,
                'opsz' 48;
        }

        .fadeout {
            background: #8083ce;
            animation: fadeBackground 3s;
            animation-fill-mode: forwards;
        }

        @keyframes fadeBackground {
            from {
                background-color: #8083ce;
            }
            to {
                background-color: transparent;
            }
        }
    `,
})
export class ViewAttachementsComponent {
    private readonly destroyRef: DestroyRef = inject(DestroyRef);
    private readonly dialog = inject(MatDialog);

    public readonly $files = input.required<AttachementDto[]>({ alias: 'files' });
    public readonly $descFiles = computed(() => {
        const files = [...this.$files()];

        return files.sort((a, b) => b.created! - a.created!);
    });
    public readonly $filesDownloading = input.required<Record<number, boolean>>({ alias: 'filesDownloading' });
    public readonly $filesDeleting = input.required<Record<number, boolean>>({ alias: 'filesDeleting' });
    public readonly $fileViewLoadingState = input.required<Record<number, boolean>>({ alias: 'fileViewLoadingState' });
    public readonly $fileUploading = input.required<boolean>({ alias: 'fileUploading' });
    public readonly $lastUploadedFileId = input.required<null | number>({ alias: 'fileToHighlight' });
    public readonly $filesUploading = input.required<AttachementUploading[]>({ alias: 'filesUploading' });

    public readonly deleteFile = output<AttachementDto>();
    public readonly downloadFile = output<AttachementDto>();
    public readonly viewImage = output<ViewFileData>();
    public readonly viewText = output<ViewFileData>();
    public readonly viewUnsupported = output<ViewFileData>();
    public readonly highlight = output<void>();

    public readonly FileType = FileType;
    public isMenuOpened: boolean = false;
    public fileId: number | undefined;
    public FileViewMode = FileViewMode;

    public onDeleteFileDialog(file: AttachementDto) {
        const dialogRef = this.dialog.open(DeleteFileDialogComponent, {
            data: { file },
        });

        const sub = dialogRef.componentInstance.deleteFile.subscribe(() => {
            this.deleteFile.emit(file);
            dialogRef.close();
        });
        this.destroyRef.onDestroy(() => {
            sub.unsubscribe();
        });
    }

    public onDownloadFile(file: AttachementDto) {
        this.downloadFile.emit(file);
        this.onCloseMenu();
    }

    public onViewFile(file: AttachementDto, viewMode: FileViewMode) {
        const docType: FileType = getFileType(file.fileType);

        switch (docType) {
            case FileType.Image:
                this.viewImage.emit({ file, viewMode });
                break;
            case FileType.Unsupported:
            case FileType.Video:
            case FileType.Audio:
                this.viewUnsupported.emit({ file, viewMode });
                break;
            default:
                this.viewText.emit({ file, viewMode });
                break;
        }

        this.onCloseMenu();
    }

    public onOpenMenu(fileId: number | void) {
        if (fileId) {
            this.fileId = fileId;
        }
    }

    public onCloseMenu() {
        this.fileId = undefined;
    }

    public onHighlightEnd() {
        this.highlight.emit();
    }
}
