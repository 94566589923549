<pdf-shy-button
  primaryToolbarId="primaryZoomIn"
  [cssClass]="showZoomButtons | responsiveCSSClass : 'always-visible'"
  class="zoomIn"
  title="Zoom In"
  l10nId="pdfjs-zoom-in-button"
  l10nLabel="pdfjs-zoom-in-button-label"
  eventBusName="zoomin"
  [order]="1600"
  image="<svg width='24px' height='24px' viewBox='0 0 24 24'><path fill='currentColor' d='M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z' /></svg>"
  [disabled]="disabled"
>
</pdf-shy-button>
