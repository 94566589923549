import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, linkedSignal, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { MAX_SIZE_IN_BYTES, MAX_SIZE_IN_MB } from '@portal/shared/utils';

import { SnackbarService } from '@portal/core/utils';

@Component({
    selector: 'p-ui-add-attachements',
    imports: [MatIconModule, MatButtonModule, CommonModule],
    templateUrl: './add-attachements.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddAttachementsComponent {
    private readonly snackbar = inject(SnackbarService);
    public $isEmpty = input<boolean>(false, { alias: 'isEmpty' });

    public $isPanelVisible = linkedSignal(() => this.$isEmpty());
    public isDragging = false;
    public selectedFile: File | null = null;

    public readonly uploadFile = output<File>();

    public onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            Array.from(input.files).forEach((file) => {
                if (file.size < MAX_SIZE_IN_BYTES) {
                    this.uploadAttachement(file);
                } else {
                    this.snackbar.showSnackbar(`File size exceeds ${MAX_SIZE_IN_MB} MB. Please select a smaller file`);
                }
            });
        }
    }

    public onDragOver(event: DragEvent): void {
        event.preventDefault();
        this.isDragging = true;
    }

    public onDragLeave(event: DragEvent): void {
        this.isDragging = false;
    }

    public onDrop(event: DragEvent): void {
        event.preventDefault();
        this.isDragging = false;
        if (event.dataTransfer && event.dataTransfer.files.length > 0) {
            Array.from(event.dataTransfer.files).forEach((file) => {
                if (file.size < MAX_SIZE_IN_BYTES) {
                    this.uploadAttachement(file);
                } else {
                    // TODO: Move snackbar to effect level
                    this.snackbar.showSnackbar(`File size exceeds ${MAX_SIZE_IN_MB} MB. Please select a smaller file`);
                }
            });
        }
    }

    public uploadAttachement(file: File): void {
        this.uploadFile.emit(file);
        this.$isPanelVisible.set(false);
    }

    public onChangeVisibility() {
        this.$isPanelVisible.update((value) => !value);
    }
}
