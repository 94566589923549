import { Directive, inject, Input, TemplateRef } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';

import { ContainerPanelType, ContainerType } from './container.utils';

@Directive({
    selector: '[pUiContainerPanel]',
})
export class ContainerPanelDirective implements ContainerType<ContainerPanelType> {
    @Input({ required: true, alias: 'pUiContainerPanel' }) public content!: ContainerPanelType;
    @Input('pUiContainerPanelOpened') public opened: boolean | undefined;
    @Input('pUiContainerPanelMode') public mode: MatDrawerMode | undefined;

    public readonly templateRef = inject(TemplateRef);
}
