import { CdkPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { AsyncPipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ContentChildren,
    DestroyRef,
    Directive,
    inject,
    Injectable,
    OnInit,
    output,
    QueryList,
    Signal,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDrawerMode, MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { map, merge, Observable, pipe, ReplaySubject, Subject, UnaryFunction } from 'rxjs';

import { BpService } from '../bp';
import { ContainerContentDirective } from './container-content.directive';
import { ContainerPanelDirective } from './container-panel.directive';
import { ContainerContentType, ContainerPanelType, ContainerType } from './container.utils';

@Injectable()
export class ContainerShellApiService {
    private readonly _containerShellBloc = inject(ContainerShellBloc);

    public setSidenav(templateRef: TemplateRef<any>): void {
        this._containerShellBloc.setSidenav(templateRef);
    }

    public toggleSidenav(): void {
        this._containerShellBloc.toggleSidenav();
    }

    public openSidenav(): void {
        this._containerShellBloc.openSidenav();
    }

    public closeSidenav(): void {
        this._containerShellBloc.closeSidenav();
    }

    public setDrawer(templateRef: TemplateRef<any>): void {
        this._containerShellBloc.setDrawer(templateRef);
    }

    public toggleDrawer(): void {
        this._containerShellBloc.toggleDrawer();
    }

    public openDrawer(): void {
        this._containerShellBloc.openDrawer();
    }

    public closeDrawer(): void {
        this._containerShellBloc.closeDrawer();
    }

    public drawerOpenedChanges(): Observable<boolean> {
        return this._containerShellBloc.drawerOpenedChanges();
    }

    public backdropClickChanges(): Observable<void> {
        return this._containerShellBloc.backdropClickChanges();
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'p-ui-container-shell[api]',
    exportAs: 'api',
})
export class ContainerShellApiDirective extends ContainerShellApiService {
    public readonly backdropClick = output<void>();

    constructor() {
        super();

        this.backdropClickChanges()
            .pipe(takeUntilDestroyed())
            .subscribe(() => this.backdropClick.emit());
    }
}

@Injectable()
class ContainerShellBloc {
    private readonly _sidenavSource: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
    private readonly _toggleSidenavSource: Subject<void> = new Subject<void>();
    private readonly _openedSidenavSource: Subject<void> = new Subject<void>();
    private readonly _closedSidenavSource: Subject<void> = new Subject<void>();

    private readonly _drawerSource: Subject<TemplateRef<any>> = new Subject<TemplateRef<any>>();
    private readonly _toggleDrawerSource: Subject<void> = new Subject<void>();
    private readonly _openDrawerActionSource: Subject<void> = new Subject<void>();
    private readonly _closedDrawerSource: Subject<void> = new Subject<void>();
    private readonly _drawerOpenedSource$: Subject<boolean> = new ReplaySubject<boolean>(1);

    private readonly _backdropClickSource$: Subject<void> = new Subject<void>();

    public readonly sidenavChanges$: Observable<TemplateRef<any>> = this._sidenavSource.asObservable();
    public readonly toggleSidenavChanges$: Observable<void> = this._toggleSidenavSource.asObservable();
    public readonly openSidenavChanges$: Observable<void> = this._openedSidenavSource.asObservable();
    public readonly closeSidenavChanges$: Observable<void> = this._closedSidenavSource.asObservable();

    public readonly drawerChanges$: Observable<TemplateRef<any>> = this._drawerSource.asObservable();
    public readonly toggleDrawerChanges$: Observable<void> = this._toggleDrawerSource.asObservable();
    public readonly openDrawerAction$: Observable<void> = this._openDrawerActionSource.asObservable();
    public readonly closeDrawerChanges$: Observable<void> = this._closedDrawerSource.asObservable();

    // sidenav

    public setSidenav(templateRef: TemplateRef<any>): void {
        this._sidenavSource.next(templateRef);
    }

    public toggleSidenav(): void {
        this._toggleSidenavSource.next();
    }

    public openSidenav(): void {
        this._openedSidenavSource.next();
    }

    public closeSidenav(): void {
        this._closedSidenavSource.next();
    }

    // drawer

    public setDrawer(templateRef: TemplateRef<any>, backdropClick: VoidFunction = (): void => void 0): void {
        this._drawerSource.next(templateRef);
    }

    public toggleDrawer(): void {
        this._toggleDrawerSource.next();
    }

    public openDrawer(): void {
        this._openDrawerActionSource.next();
    }

    public closeDrawer(): void {
        this._closedDrawerSource.next();
    }

    public setDrawerOpened(opened: boolean): void {
        this._drawerOpenedSource$.next(opened);
    }

    public drawerOpenedChanges(): Observable<boolean> {
        return this._drawerOpenedSource$.asObservable();
    }

    public backdropClickAction(): void {
        this._backdropClickSource$.next();
    }

    public backdropClickChanges(): Observable<void> {
        return this._backdropClickSource$.asObservable();
    }
}

@Component({
    selector: 'p-ui-container-shell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatSidenav, MatSidenavModule, AsyncPipe, CdkPortalOutlet],
    providers: [ContainerShellBloc, ContainerShellApiService],
    templateUrl: './container-shell.component.html',
})
export class ContainerShellComponent implements OnInit {
    private readonly viewContainerRef = inject(ViewContainerRef);

    @ViewChild('matSidenavRef', { read: MatSidenav, static: false }) private readonly matSidenav!: MatSidenav;
    @ViewChild('matDrawerRef', { read: MatSidenav, static: false }) private readonly matDrawer!: MatSidenav;

    private readonly _contentsSource: Subject<QueryList<ContainerContentDirective>> = new ReplaySubject<
        QueryList<ContainerContentDirective>
    >(1);
    private readonly _panelsSource: Subject<QueryList<ContainerPanelDirective>> = new ReplaySubject<QueryList<ContainerPanelDirective>>(1);

    @ContentChildren(ContainerContentDirective, { read: ContainerContentDirective })
    public set contents(value: QueryList<ContainerContentDirective>) {
        if (value) {
            this._contentsSource.next(value);
        }
    }

    @ContentChildren(ContainerPanelDirective, { read: ContainerPanelDirective })
    public set panels(value: QueryList<ContainerPanelDirective>) {
        if (value) {
            this._panelsSource.next(value);
        }
    }

    private readonly _bp = inject(BpService);
    private readonly _bloc = inject(ContainerShellBloc);
    private readonly _destroyRef = inject(DestroyRef);

    public readonly footerTpl$: Observable<TemplatePortal | null> = this._contentChanges('footer');
    public readonly asideTpl$: Observable<TemplatePortal | null> = this._contentChanges('aside');
    public readonly contentTpl$: Observable<TemplatePortal | null> = this._contentChanges('content');

    public readonly panelSidenavTpl$: Observable<TemplatePortal | null> = merge(
        this._bloc.sidenavChanges$,
        this._panelChanges('sidenav')
    ).pipe(map((templateRef: TemplateRef<any> | null) => (templateRef ? new TemplatePortal(templateRef, this.viewContainerRef) : null)));

    public readonly panelSidenavOpened$: Observable<boolean> = this._panelOpenedChanges('sidenav');
    public readonly panelDrawerTpl$: Observable<TemplatePortal | null> = merge(
        this._bloc.drawerChanges$,
        this._panelChanges('drawer')
    ).pipe(map((templateRef: TemplateRef<any> | null) => (templateRef ? new TemplatePortal(templateRef, this.viewContainerRef) : null)));
    public readonly panelDrawerOpened$: Observable<boolean> = this._panelOpenedChanges('drawer');

    private readonly $isDesktop: Signal<boolean | undefined> = toSignal(this._bp.isDesktop());

    public readonly $sidenavMode: Signal<MatDrawerMode> = toSignal(
        this._bp.isMobile().pipe(map((isMobile: boolean) => (isMobile ? 'over' : 'side'))),
        { initialValue: 'over' }
    );

    // Drawer mode
    readonly #dynamicDrawerMode: Signal<MatDrawerMode> = toSignal(this._bp.minXL().pipe(map((lg: boolean) => (lg ? 'side' : 'over'))), {
        initialValue: 'side',
    });
    readonly #userDrawerMode: Signal<MatDrawerMode | null> = toSignal(this._panelModeChanges('drawer'), { initialValue: null });
    public readonly $drawerMode: Signal<MatDrawerMode> = computed(() => {
        const dynamicMode = this.#dynamicDrawerMode();
        const userMode: MatDrawerMode | null = this.#userDrawerMode();

        return userMode ?? dynamicMode;
    });

    private static filterByContent<T, R extends ContainerType<T>>(
        contentType: T
    ): UnaryFunction<Observable<QueryList<R>>, Observable<R | undefined>> {
        return pipe(map((directive: QueryList<R>) => directive.find((directive: R) => directive.content === contentType)));
    }

    private readonly _sidenavOpenedSource: Subject<boolean> = new ReplaySubject<boolean>(1);
    public readonly $sidenavOpened: Signal<boolean> = toSignal(merge(this._sidenavOpenedSource, this.panelSidenavOpened$), {
        initialValue: false,
    });

    private readonly _drawerOpenedSource: Subject<boolean> = new ReplaySubject<boolean>(1);
    public readonly $drawerOpened: Signal<boolean> = toSignal(this._bloc.drawerOpenedChanges(), {
        initialValue: false,
    });

    public ngOnInit(): void {
        this._bloc.toggleSidenavChanges$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => void this.matSidenav.toggle());
        this._bloc.toggleDrawerChanges$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => void this.matDrawer.toggle());

        this._bloc.openSidenavChanges$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => void this.matSidenav.open());
        this._bloc.openDrawerAction$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => void this.matDrawer.open());

        this._bloc.closeSidenavChanges$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => void this.matSidenav.close());
        this._bloc.closeDrawerChanges$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => void this.matDrawer.close());

        merge(this._drawerOpenedSource, this.panelDrawerOpened$)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((drawerOpened: boolean) => {
                this._bloc.setDrawerOpened(drawerOpened);
            });
    }

    public onSidenavOpen(): void {
        this._sidenavOpenedSource.next(true);
    }

    public onSidenavOpenChange(opened: boolean): void {
        if (!opened) {
            this._sidenavOpenedSource.next(false);
        }
    }

    public onDrawerOpen(): void {
        this._drawerOpenedSource.next(true);
    }

    public onDrawerOpenChange(opened: boolean): void {
        if (!opened) {
            this._drawerOpenedSource.next(false);
        }
    }

    public onBackdropClick(): void {
        this._bloc.backdropClickAction();
    }

    private _contentChanges(contentType: ContainerContentType): Observable<TemplatePortal | null> {
        return this._contentsSource.pipe(
            ContainerShellComponent.filterByContent(contentType),
            map((directive: ContainerContentDirective | undefined) => directive?.templateRef ?? null),
            map((templateRef: TemplateRef<any> | null) => (templateRef ? new TemplatePortal(templateRef, this.viewContainerRef) : null))
        );
    }

    private _panelChanges(contentType: ContainerPanelType): Observable<TemplateRef<any> | null> {
        return this._panelsSource.pipe(
            ContainerShellComponent.filterByContent(contentType),
            map((directive: ContainerPanelDirective | undefined) => directive?.templateRef ?? null)
        );
    }

    private _panelOpenedChanges(contentType: ContainerPanelType): Observable<boolean> {
        return this._panelsSource.pipe(
            ContainerShellComponent.filterByContent(contentType),
            map((panel: ContainerPanelDirective | undefined) => !!this.$isDesktop() && !!panel?.opened)
        );
    }

    private _panelModeChanges(contentType: ContainerPanelType): Observable<MatDrawerMode | null> {
        return this._panelsSource.pipe(
            ContainerShellComponent.filterByContent(contentType),
            map((panel: ContainerPanelDirective | undefined) => panel?.mode ?? null)
        );
    }
}
