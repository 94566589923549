<p-ui-container-shell api #api="api">
    <!-- Aside -->
    <ng-container *pUiContainerContent="'aside'">
        <div class="border-ds-bright-100 center flex h-full border">aside</div>
    </ng-container>
    <!-- Content-->
    <ng-container *pUiContainerContent="'content'">
        <div class="border-ds-bright-100 center flex flex-col gap-4 border p-4">
            <div class="center flex grow">
                <div class="flex flex-col gap-4">
                    <button mat-button class="primary" (click)="api.toggleSidenav()">Toggle Sidenav</button>
                    <button mat-button class="primary" (click)="api.closeSidenav()">Close Sidenav</button>
                    <mat-divider />
                    <button mat-button class="primary" (click)="api.setSidenav(sidenav1Ref); api.openSidenav()">Sidenav 1</button>
                    <button mat-button class="primary" (click)="api.setSidenav(sidenav2Ref); api.openSidenav()">Sidenav 2</button>
                    <button mat-button class="primary" (click)="api.setSidenav(sidenav3Ref); api.openSidenav()">Sidenav 3</button>
                </div>

                <mat-divider [vertical]="true" />

                <div class="flex flex-col gap-4">
                    <button mat-button class="primary" (click)="api.toggleDrawer()">Toggle Drawer</button>
                    <button mat-button class="primary" (click)="api.closeDrawer()">Close Drawer</button>
                    <mat-divider />
                    <button mat-button class="primary" (click)="api.setDrawer(drawer1Ref); api.openDrawer()">Open Drawer 1</button>
                    <button mat-button class="primary" (click)="api.setDrawer(drawer2Ref); api.openDrawer()">Open Drawer 2</button>
                    <button mat-button class="primary" (click)="api.setDrawer(drawer3Ref); api.openDrawer()">Open Drawer 3</button>
                </div>
            </div>

            <mat-divider />

            <div>
                High quality Internationalized and accessible components for everyone. Well tested to ensure performance and reliability.
                Straightforward APIs with consistent cross platform behaviour. Versatile Provide tools that help developers build their own
                custom components with common interaction patterns. Customizable within the bounds of the Material Design specification.
                Frictionless Built by the Angular team to integrate seamlessly with Angular. Start from scratch or drop into your existing
                applications.
            </div>
        </div>
    </ng-container>
    <!-- Footer -->
    <ng-container *pUiContainerContent="'footer'">
        <div class="border-ds-bright-100 center flex border">footer</div>
    </ng-container>

    <!-- Sidenav -->
    <ng-container *pUiContainerPanel="'sidenav'; opened: true" />
    <!-- Drawer -->
    <ng-container *pUiContainerPanel="'drawer'; opened: true" />
</p-ui-container-shell>

<ng-template #sidenav1Ref>
    <div class="border-ds-bright-100 center flex h-full border">Sidenav 1</div>
</ng-template>
<ng-template #sidenav2Ref>
    <div class="border-ds-bright-100 center flex h-full border">Sidenav 2</div>
</ng-template>
<ng-template #sidenav3Ref>
    <div class="border-ds-bright-100 center flex h-full border">Sidenav 3</div>
</ng-template>

<ng-template #drawer1Ref>
    <div class="border-ds-bright-100 center flex h-full border">Drawer 1</div>
</ng-template>
<ng-template #drawer2Ref>
    <div class="border-ds-bright-100 center flex h-full border">Drawer 2</div>
</ng-template>
<ng-template #drawer3Ref>
    <div class="border-ds-bright-100 center flex h-full border">Drawer 3</div>
</ng-template>
