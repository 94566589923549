import { Pipe, PipeTransform } from '@angular/core';

import { ViewModeEnum } from './view-mode.enum';

@Pipe({
    name: 'isViewMode',
})
export class IsViewModePipe implements PipeTransform {
    public transform(value: ViewModeEnum, viewMode: keyof typeof ViewModeEnum): boolean {
        return value === viewMode;
    }
}

@Pipe({
    name: 'isNotViewMode',
})
export class IsNotViewModePipe implements PipeTransform {
    public transform(value: ViewModeEnum, viewMode: keyof typeof ViewModeEnum): boolean {
        return value !== viewMode;
    }
}
