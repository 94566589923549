@if (counterClockwise) {
  <pdf-shy-button
    [action]="rotateCCW"
    primaryToolbarId="primaryPageRotateCcw"
    class="rotateCcw"
    [cssClass]="showRotateCcwButton | responsiveCSSClass : 'hiddenXLView'"
    title="Rotate Counterclockwise"
    l10nId="pdfjs-page-rotate-ccw-button"
    l10nLabel="pdfjs-page-rotate-ccw-button-label"
    [disabled]="disableRotate"
    [order]="1000"
    [closeOnClick]="false"
    image="<svg class='width-height-23px' viewBox='0 0 24 24'><path fill='currentColor' d='M12 3C7.03 3 3 7.03 3 12H0L4 16L8 12H5C5 8.13 8.13 5 12 5S19 8.13 19 12 15.87 19 12 19C10.55 19 9.13 18.54 7.94 17.7L6.5 19.14C8.08 20.34 10 21 12 21C16.97 21 21 16.97 21 12S16.97 3 12 3'/></svg>"
    >
  </pdf-shy-button>
}
