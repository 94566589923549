import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'lib-ui-no-data-snippet',
    imports: [MatIcon],
    template: `
        <div class="flex min-h-screen items-center justify-center bg-gray-100">
            <div class="w-full max-w-sm rounded-lg border border-gray-200 bg-white p-6 shadow-md sm:p-8">
                <div class="flex flex-col items-center">
                    <!-- Using a more visually appealing Angular Material icon -->
                    <mat-icon class="m-6 scale-[2] text-gray-400">sentiment_dissatisfied</mat-icon>
                    <h3 class="mb-4 text-lg font-semibold text-gray-900">No Data Available</h3>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class NoDataSnippet {}
