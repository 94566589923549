import { Injectable, inject } from '@angular/core';

import { CountriesResource } from './countries-resource';

@Injectable()
export class CountriesResourceController {
    private resource = inject(CountriesResource);

    public getCountries() {
        return this.resource.getCountries();
    }

    public getCountryByCode(countryCode: string) {
        return this.resource.getCountryByCode(countryCode);
    }

    public getCurrencies() {
        return this.resource.getCurrencies();
    }
}
