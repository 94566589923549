import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'p-ui-placeholder-team',
    templateUrl: './placeholder-team.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatButton],
})
export class PlaceholderTeamComponent {}
