<svg xmlns="http://www.w3.org/2000/svg" [style.width]="width + 'px'" [style.height]="height + 'px'" viewBox="-60 -60 574 632">
    <path
        d="M354.23 331.465L241.682 396.448V511.981L454.34 389.222V122.778L241.682 0V115.552L354.23 180.516V225.795H283.105L256.969 210.682L230.793 195.57L204.637 210.682L178.501 225.795V256V286.186L204.637 301.298L230.793 316.411L256.969 301.298L283.105 286.186H354.23V331.465Z"
        class="{{
            type === LogoTypes.Light
                ? 'fill-ds-light-500'
                : type === LogoTypes.Colored
                  ? 'fill-ds-purple-500'
                  : 'fill-ds-light-500 brightness-[0.70]'
        }}"
    />
    <path
        d="M100.129 180.516L212.678 115.532V0L0 122.778V389.222L212.678 512.019V396.468L100.129 331.484V180.535V180.516Z"
        class="{{
            type === LogoTypes.Light ? 'fill-ds-orange-500' : type === LogoTypes.Colored ? 'fill-ds-orange-500' : 'fill-ds-light-50'
        }}"
    />
</svg>
