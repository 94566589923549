import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { DisciplineDto, DisciplineEntity, DisciplinesResource, Page } from '@portal/shared/utils';

@Injectable()
export class DisciplinesResourceController {
    readonly #resource: DisciplinesResource = inject(DisciplinesResource);

    public getDisciplines(): Observable<Page<DisciplineEntity>> {
        return this.#resource.getDisciplines();
    }

    public getDiscipline(disciplineId: number): Observable<DisciplineEntity> {
        return this.#resource.getDiscipline(disciplineId);
    }

    public getDisciplinesByIds(disciplineIds: number[]): Observable<DisciplineEntity[]> {
        return forkJoin(disciplineIds.map((id) => this.getDiscipline(id)));
    }

    public createDiscipline(discipline: DisciplineDto): Observable<DisciplineEntity> {
        return this.#resource.createDiscipline(discipline);
    }

    public deleteDiscipline(disciplineId: number): Observable<DisciplineEntity> {
        return this.#resource.deleteDiscipline(disciplineId);
    }

    public canDeleteDiscipline(disciplineId: number): Observable<void> {
        return this.#resource.canDeleteDiscipline(disciplineId);
    }

    public updateDiscipline(discipline: DisciplineDto): Observable<DisciplineEntity> {
        return this.#resource.updateDiscipline(discipline);
    }
}
