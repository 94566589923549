<pdf-shy-button
  [action]="onClick"
  [toggled]="isSelected"
  primaryToolbarId="primaryCursorHandTool"
  [cssClass]="showHandToolButton | responsiveCSSClass : 'hiddenXLView'"
  title="Enable hand tool"
  l10nId="pdfjs-cursor-hand-tool-button"
  l10nLabel="pdfjs-cursor-hand-tool-button-label"
  [order]="1200"
  image="<svg width='22px' height='22px' viewBox='0 0 24 24'> <path fill='currentColor' d='M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z'/></svg>"
>
</pdf-shy-button>
