/**
 * @see [Tailwind Responsive Design](https://tailwindcss.com/docs/responsive-design)
 *
 * @example
 * Breakpoint prefix	Minimum width	CSS
 * sm	640px	@media (min-width: 640px) { ... }
 * md	768px	@media (min-width: 768px) { ... }
 * lg	1024px	@media (min-width: 1024px) { ... }
 * xl	1280px	@media (min-width: 1280px) { ... }
 * 2xl	1536px	@media (min-width: 1536px) { ... }
 */
export const enum TAILWIND_BREAKPOINT_PREFIX {
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XXL = '2xl',
}

export type TailwindBreakpointPrefixType = `${TAILWIND_BREAKPOINT_PREFIX}`;

export const tailwindBreakpointMedia: Map<string, TailwindBreakpointPrefixType> = new Map<string, TailwindBreakpointPrefixType>([
    ['(min-width: 640px)', TAILWIND_BREAKPOINT_PREFIX.SM],
    ['(min-width: 768px)', TAILWIND_BREAKPOINT_PREFIX.MD],
    ['(min-width: 1024px)', TAILWIND_BREAKPOINT_PREFIX.LG],
    ['(min-width: 1280px)', TAILWIND_BREAKPOINT_PREFIX.XL],
    ['(min-width: 1536px)', TAILWIND_BREAKPOINT_PREFIX.XXL],
] as const);
