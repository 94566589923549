import { Directive, ElementRef, HostListener, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, output } from '@angular/core';
import { NgControl } from '@angular/forms';

import { InputTelInput, IntlTelInputCountryCode, IntlTelInputType } from './int-tel.type';

const PHONE_MASK: string = '000-000-0000 x0000000000';

function parseByMask(value: string, mask: string): string {
    let newValue: string = value.replace(/[^0-9]/g, '');
    if (newValue.length > 0) {
        let str: string = '';
        const valueArray: string[] = newValue.split('');
        for (let i: number = 0; i < mask.length; i++) {
            if (valueArray.length === 0) {
                break;
            }

            if (mask.charAt(i) === '0') {
                const el: string = valueArray.shift()!;
                str += el;
            } else {
                str += mask.charAt(i);
            }
        }

        newValue = str;
    }

    return newValue;
}

@Directive({
    selector: 'input.input-intl-tel[pUiInputIntlTel]',
})
export class InputIntlTelDirective implements OnChanges, OnInit, OnDestroy {
    @Input({ alias: 'pUiInputIntlTel', required: true }) public defaultCountry!: IntlTelInputCountryCode | null;
    public readonly intlTelInstanceReady = output<IntlTelInputType>();

    private iti!: IntlTelInputType;

    private readonly elementRef: ElementRef<HTMLInputElement> = inject(ElementRef);
    private readonly control: NgControl | null = inject(NgControl, { optional: true, self: true });

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['defaultCountry'] && changes['defaultCountry'].previousValue !== changes['defaultCountry'].currentValue) {
            if (!this.iti) {
                this._initializeInput();
            } else if (!this.control?.value) {
                this.iti.destroy();
                this._initializeInput();
            }
        }
    }

    public ngOnInit(): void {
        this.elementRef.nativeElement.addEventListener('countrychange', this.onSelectCountry.bind(this));
    }

    public ngOnDestroy(): void {
        this.elementRef.nativeElement.removeEventListener('countrychange', this.onSelectCountry.bind(this));
        this.iti.destroy();
    }

    @HostListener('input', ['$event'])
    public onInput(event: InputEvent): void {
        this.parseInputValue(event);
    }

    @HostListener('blur', ['$event'])
    public onBlur(event: FocusEvent): void {
        this.parseInputValue(event);
    }

    private onSelectCountry(event: Event): void {
        // const inputValue: HTMLInputElement = event.target as HTMLInputElement;
        this.parseInputValue(event, true);
    }

    private parseInputValue(event: InputEvent | FocusEvent | Event, changeCountry: boolean = false): void {
        const checkLastSpace: boolean = event instanceof FocusEvent;
        const inputElement: HTMLInputElement = this.elementRef.nativeElement;
        const value: string = inputElement.value;
        let newValue: string = parseByMask(value, PHONE_MASK);

        if (value.startsWith(' ') || (checkLastSpace && value.endsWith(' '))) {
            newValue = value.trim();
        }

        if (value.match(/\s{2,}/g)) {
            newValue = value.replace(/\s{2,}/g, ' ');
        }

        if (changeCountry || value !== newValue) {
            inputElement.value = newValue;
            this.control?.control?.patchValue(newValue);
        }
    }

    private _initializeInput(): void {
        this.iti = InputTelInput(this.elementRef.nativeElement, {
            initialCountry: this.defaultCountry ?? '',
            separateDialCode: true,
            countryOrder: ['ca', 'us'],
        });

        this.intlTelInstanceReady.emit(this.iti);
    }
}
