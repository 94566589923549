import { Directive, inject, Input, TemplateRef } from '@angular/core';

import { ContainerContentType, ContainerType } from './container.utils';

@Directive({
    selector: '[pUiContainerContent]',
})
export class ContainerContentDirective implements ContainerType<ContainerContentType> {
    @Input({ required: true, alias: 'pUiContainerContent' }) public content!: ContainerContentType;
    @Input('pUiContainerContentOpened') public opened: boolean = false;

    public readonly templateRef = inject(TemplateRef);
}
