import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, Observable, ReplaySubject, Subject } from 'rxjs';

import { TAILWIND_BREAKPOINT_PREFIX, tailwindBreakpointMedia, TailwindBreakpointPrefixType } from './bp.model';

@Injectable({ providedIn: 'root' })
export class BpService {
    private readonly _mediaSource: Subject<TailwindBreakpointPrefixType | null> = new ReplaySubject<TailwindBreakpointPrefixType | null>(1);
    public readonly media$: Observable<TailwindBreakpointPrefixType | null> = this._mediaSource.asObservable();
    private readonly _mediasSource: Subject<TailwindBreakpointPrefixType[]> = new ReplaySubject<TailwindBreakpointPrefixType[]>(1);
    public readonly medias$: Observable<TailwindBreakpointPrefixType[]> = this._mediasSource.asObservable();

    constructor() {
        const breakpointObserver = inject(BreakpointObserver);
        const breakpointState$: Observable<BreakpointState> = breakpointObserver.observe(Array.from(tailwindBreakpointMedia.keys()));

        const currentBreakpoint$: Observable<TailwindBreakpointPrefixType | null> = breakpointState$.pipe(
            map((result: BreakpointState) =>
                Object.keys(result.breakpoints)
                    .reverse()
                    .find((query: string) => result.breakpoints[query] && tailwindBreakpointMedia.has(query))
            ),
            map((query: string | undefined) => (query !== undefined ? tailwindBreakpointMedia.get(query)! : null))
        );

        currentBreakpoint$.pipe(takeUntilDestroyed()).subscribe((media: TailwindBreakpointPrefixType | null) => {
            this._mediaSource.next(media);
        });

        const currentBreakpoints$: Observable<TailwindBreakpointPrefixType[]> = breakpointState$.pipe(
            map((result: BreakpointState) =>
                Object.keys(result.breakpoints).filter((query: string) => result.breakpoints[query] && tailwindBreakpointMedia.has(query))
            ),
            map((queries: string[]) => queries.map((query: string) => tailwindBreakpointMedia.get(query)!))
        );

        currentBreakpoints$
            .pipe(takeUntilDestroyed())
            .subscribe((medias: TailwindBreakpointPrefixType[]) => this._mediasSource.next(medias));
    }

    public isMobile(): Observable<boolean> {
        return this.isDesktop().pipe(map((isDesktop: boolean) => !isDesktop));
    }

    public isDesktop(): Observable<boolean> {
        return this.media$.pipe(
            map(
                (media: TailwindBreakpointPrefixType | null) =>
                    media === TAILWIND_BREAKPOINT_PREFIX.MD ||
                    media === TAILWIND_BREAKPOINT_PREFIX.LG ||
                    media === TAILWIND_BREAKPOINT_PREFIX.XL ||
                    media === TAILWIND_BREAKPOINT_PREFIX.XXL
            )
        );
    }

    public minSM(): Observable<boolean> {
        return this.mediasIncludes(TAILWIND_BREAKPOINT_PREFIX.SM);
    }

    public minMD(): Observable<boolean> {
        return this.mediasIncludes(TAILWIND_BREAKPOINT_PREFIX.MD);
    }

    public minLG(): Observable<boolean> {
        return this.mediasIncludes(TAILWIND_BREAKPOINT_PREFIX.LG);
    }

    public minXL(): Observable<boolean> {
        return this.mediasIncludes(TAILWIND_BREAKPOINT_PREFIX.XL);
    }

    public minXXL(): Observable<boolean> {
        return this.mediasIncludes(TAILWIND_BREAKPOINT_PREFIX.XXL);
    }

    private mediasIncludes(media: TailwindBreakpointPrefixType): Observable<boolean> {
        return this.medias$.pipe(map((medias: TailwindBreakpointPrefixType[]) => medias.includes(media)));
    }
}
