<div class="p-4">
    <div class="flex flex-wrap gap-5 p-4">
        <mat-icon class="mt-2 scale-[2]">account_circle</mat-icon>
        <div class="flex flex-col">
            <div class="flex flex-col">
                <div>Bob Simons</div>
                <div>{{ 'bob.simons@email.com' }}</div>
            </div>
            <div class="text-ds-purple-500 ml-2 mt-4 max-w-max cursor-pointer">View profile</div>
        </div>
    </div>
    <div class="mr-4 flex w-full justify-end">
        <button mat-raised-button color="primary">Sign out</button>
    </div>
</div>
