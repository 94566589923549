<dialog class="dialog altText" id="altTextDialog" aria-labelledby="dialogLabel" aria-describedby="dialogDescription">
  <div id="altTextContainer" class="mainContainer">
    <div id="overallDescription">
      <span id="dialogLabel" data-l10n-id="pdfjs-editor-alt-text-dialog-label" class="title">Choose an option</span>
      <span id="dialogDescription" data-l10n-id="pdfjs-editor-alt-text-dialog-description">
        Alt text (alternative text) helps when people can’t see the image or when it doesn’t load.
      </span>
    </div>
    <div id="addDescription">
      <div class="radio">
        <div class="radioButton">
          <input type="radio" id="descriptionButton" name="altTextOption" aria-describedby="descriptionAreaLabel"
            checked />
          <label for="descriptionButton" data-l10n-id="pdfjs-editor-alt-text-add-description-label">Add a
            description</label>
        </div>
        <div class="radioLabel">
          <span id="descriptionAreaLabel" data-l10n-id="pdfjs-editor-alt-text-add-description-description">
            Aim for 1-2 sentences that describe the subject, setting, or actions.
          </span>
        </div>
      </div>
      <div class="descriptionArea">
        <textarea id="descriptionTextarea" placeholder="For example, “A young man sits down at a table to eat a meal”"
          aria-labelledby="descriptionAreaLabel" data-l10n-id="pdfjs-editor-alt-text-textarea"></textarea>
      </div>
    </div>
    <div id="markAsDecorative">
      <div class="radio">
        <div class="radioButton">
          <input type="radio" id="decorativeButton" name="altTextOption" aria-describedby="decorativeLabel" />
          <label for="decorativeButton" data-l10n-id="pdfjs-editor-alt-text-mark-decorative-label">Mark as
            decorative</label>
        </div>
        <div class="radioLabel">
          <span id="decorativeLabel" data-l10n-id="pdfjs-editor-alt-text-mark-decorative-description">
            This is used for ornamental images, like borders or watermarks.
          </span>
        </div>
      </div>
    </div>
    <div id="buttons">
      <button id="altTextCancel" class="secondaryButton" type="button" tabindex="0"><span
          data-l10n-id="pdfjs-editor-alt-text-cancel-button">Cancel</span></button>
      <button id="altTextSave" class="primaryButton" type="button" tabindex="0"><span
          data-l10n-id="pdfjs-editor-alt-text-save-button">Save</span></button>
    </div>
  </div>
</dialog>
<dialog class="dialog newAltText" id="newAltTextDialog" aria-labelledby="newAltTextTitle"
  aria-describedby="newAltTextDescription" tabindex="0">
  <div id="newAltTextContainer" class="mainContainer">
    <div class="title">
      <span id="newAltTextTitle" data-l10n-id="pdfjs-editor-new-alt-text-dialog-edit-label" role="sectionhead"
        tabindex="0">Edit alt text (image description)</span>
    </div>
    <div id="mainContent">
      <div id="descriptionAndSettings">
        <div id="descriptionInstruction">
          <div id="newAltTextDescriptionContainer">
            <div class="altTextSpinner" role="status" aria-live="polite"></div>
            <textarea id="newAltTextDescriptionTextarea" placeholder="Write your description here…"
              aria-labelledby="descriptionAreaLabel" data-l10n-id="pdfjs-editor-new-alt-text-textarea"
              tabindex="0"></textarea>
          </div>
          <span id="newAltTextDescription" role="note" data-l10n-id="pdfjs-editor-new-alt-text-description">Short
            description for people who can’t see the image or when the image doesn’t load.</span>
          <div id="newAltTextDisclaimer" role="note">
            <div><span data-l10n-id="pdfjs-editor-new-alt-text-disclaimer1">This alt text was created automatically and
                may be inaccurate.</span> <a href="https://support.mozilla.org/en-US/kb/pdf-alt-text" target="_blank"
                rel="noopener noreferrer" id="newAltTextLearnMore"
                data-l10n-id="pdfjs-editor-new-alt-text-disclaimer-learn-more-url" tabindex="0">Learn more</a></div>
          </div>
        </div>
        <div id="newAltTextCreateAutomatically" class="toggler">
          <button id="newAltTextCreateAutomaticallyButton" class="toggle-button" type="button" aria-pressed="true"
            tabindex="0"></button>
          <label for="newAltTextCreateAutomaticallyButton" class="togglerLabel"
            data-l10n-id="pdfjs-editor-new-alt-text-create-automatically-button-label">Create alt text
            automatically</label>
        </div>
        <div id="newAltTextDownloadModel" class="hidden">
          <span id="newAltTextDownloadModelDescription"
            data-l10n-id="pdfjs-editor-new-alt-text-ai-model-downloading-progress" aria-valuemin="0"
            data-l10n-args='{ "totalSize": 0, "downloadedSize": 0 }'>Downloading alt text AI model (0 of 0 MB)</span>
        </div>
      </div>
      <div id="newAltTextImagePreview"></div>
    </div>
    <div id="newAltTextError" class="messageBar">
      <div>
        <div>
          <span class="title" data-l10n-id="pdfjs-editor-new-alt-text-error-title">Couldn’t create alt text
            automatically</span>
          <span class="description" data-l10n-id="pdfjs-editor-new-alt-text-error-description">Please write your own alt
            text or try again later.</span>
        </div>
        <button id="newAltTextCloseButton" class="closeButton" type="button" tabindex="0" title="Close"><span
            data-l10n-id="pdfjs-editor-new-alt-text-error-close-button">Close</span></button>
      </div>
    </div>
    <div id="newAltTextButtons" class="dialogButtonsGroup">
      <button id="newAltTextCancel" type="button" class="secondaryButton hidden" tabindex="0"><span
          data-l10n-id="pdfjs-editor-alt-text-cancel-button">Cancel</span></button>
      <button id="newAltTextNotNow" type="button" class="secondaryButton" tabindex="0"><span
          data-l10n-id="pdfjs-editor-new-alt-text-not-now-button">Not now</span></button>
      <button id="newAltTextSave" type="button" class="primaryButton" tabindex="0"><span
          data-l10n-id="pdfjs-editor-alt-text-save-button">Save</span></button>
    </div>
  </div>
</dialog>