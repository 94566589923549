@let logOutProgress = store.logOutProgress();

<div class="flex flex-wrap gap-5">
    @if (store.userLoadProgress()) {
        <lib-ui-content-loader-snippet />
    } @else {
        @if (store.user(); as user) {
            <mat-icon class="text-ds-bright-500 mt-1 min-h-12 min-w-12 text-5xl">account_circle</mat-icon>
            <div class="flex flex-col">
                <div class="flex flex-col">
                    <span class="text-xl">{{ user.firstName }} {{ user.lastName }}</span>
                    <span class="text-sm">{{ user.email }}</span>
                </div>
                <button mat-button class="mt-4 w-28" (click)="onViewProfile()">View profile</button>
            </div>
            <div class="flex w-full justify-end">
                <button mat-raised-button class="primary" [disabled]="logOutProgress" (click)="store.logout()">
                    @if (logOutProgress) {
                        <mat-progress-spinner diameter="20" strokeWidth="2" mode="indeterminate" />
                    } @else {
                        Sign out
                    }
                </button>
            </div>
        } @else {
            Something went wrong
        }
    }
</div>

<ng-template #accountDrawer>
    <p-ft-account-drawer />
</ng-template>
