<input
    class="input-intl-tel w-full"
    type="tel"
    [pUiInputIntlTel]="$defaultCountry()"
    [formControl]="phoneControl"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    (intlTelInstanceReady)="onIntlTelInstanceReady($event)"
    [attr.readonly]="readOnly ? true : null"
    [placeholder]="placeholder"
/>
