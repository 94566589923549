import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';

import { AccountStore } from '@portal/shared/data-access';
import { generatePassword } from '@portal/shared/utils';

import { AuthenticatedLayoutApi } from '../authenticated-layout';

@Component({
    selector: 'p-ft-account-drawer',
    templateUrl: './account-drawer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatButton,
        MatFormField,
        MatIcon,
        MatIconButton,
        MatInput,
        MatLabel,
        MatProgressSpinner,
        MatSuffix,
        ReactiveFormsModule,
        MatTooltip,
        MatDivider,
        MatTooltipModule,
    ],
})
export class AccountDrawerComponent implements OnInit {
    readonly #destroyRef = inject(DestroyRef);

    readonly #authenticatedLayoutApi = inject(AuthenticatedLayoutApi);
    readonly #clipboard = inject(Clipboard);
    public readonly store = inject(AccountStore);

    protected editFormGroup = new FormGroup(
        {
            currentPassword: new FormControl(''),
            newPassword: new FormControl(''),
            newPasswordConfirm: new FormControl(''),
        },
        {
            validators: (control: AbstractControl): ValidationErrors | null => {
                const formGroup = control as FormGroup;
                const newPassword = formGroup.get('newPassword')?.value;
                const confirmNewPassword = formGroup.get('newPasswordConfirm')?.value;
                if (newPassword !== confirmNewPassword && newPassword && confirmNewPassword) {
                    formGroup.get('newPasswordConfirm')?.setErrors({ passwordMismatch: true });
                    return { passwordMismatch: true };
                }
                if (newPassword === confirmNewPassword) {
                    formGroup.get('newPasswordConfirm')?.setErrors(null);
                }
                return null;
            },
        }
    );

    public readonly $currentPasswordHidden = signal<boolean>(true);
    public readonly $passwordHidden = signal<boolean>(true);

    readonly #changePasswordModeSource: WritableSignal<boolean> = signal(false);
    protected $changePasswordMode = this.#changePasswordModeSource.asReadonly();

    public ngOnInit(): void {
        this.store
            .onUpdateUserSuccess()
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe(() => this.#close());
    }

    protected onToggle() {
        this.editFormGroup.reset();
        this.#changePasswordModeSource.update((state) => !state);
    }

    protected onEdit() {
        if (this.editFormGroup.valid) {
            this.store.updatePassword({
                password: this.editFormGroup.controls['currentPassword'].value!,
                newPassword: this.editFormGroup.controls['newPassword'].value!,
            });
        }
    }

    protected onClose() {
        this.#close();
    }

    #close() {
        this.#authenticatedLayoutApi.setTemplate(null);
    }

    protected onGeneratePassword(): void {
        const password = generatePassword();
        this.editFormGroup.patchValue({
            newPassword: password,
            newPasswordConfirm: password,
        });
        this.$passwordHidden.set(false);
    }

    public onCopyToClipboard(value: string): void {
        this.#clipboard.copy(value);
    }
}
