import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
    templateUrl: './address-confirmation-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatDialogContent, MatDialogActions, MatIconModule],
})
export class AddressConfirmationDialogComponent {
    readonly #dialogRef = inject<MatDialogRef<AddressConfirmationDialogComponent>>(MatDialogRef);

    public onConfirm(): void {
        this.#dialogRef.close(true);
    }

    public onCancel(): void {
        this.#dialogRef.close(false);
    }
}
