import { ConnectionPositionPair } from '@angular/cdk/overlay';

import { ClockPortalPositionsMap } from './clock-portal-positions';
import { PortalPlacement } from './portal-placement';
import { CommonPortalPositionsMap } from './portal-positions';

export const PORTAL_POSITIONS_MAP: Map<PortalPlacement, ConnectionPositionPair[]> = new Map<PortalPlacement, ConnectionPositionPair[]>([
    ...CommonPortalPositionsMap,
    ...ClockPortalPositionsMap,
]);
