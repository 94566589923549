import { Iti } from 'intl-tel-input';
import { Country } from 'intl-tel-input/data';
import _i18nEnCountries from 'intl-tel-input/i18n/en/countries';
import _intlTelInput from 'intl-tel-input/intlTelInputWithUtils';

import { LocalizationUtils } from '@portal/shared/utils';

export type SelectedCountryData =
    | Country
    | {
          name?: string;
          iso2?: string;
          dialCode?: string;
      };
export type IntlTelInputType = Iti;
export type IntlTelInputCountryCode = keyof typeof _i18nEnCountries;

export { _intlTelInput as InputTelInput };

export function convertCountryToIntlTelInputCountryCode(country: LocalizationUtils.CountryEnum | null): IntlTelInputCountryCode {
    return country && countriesMap.hasOwnProperty(country)
        ? countriesMap[country]!
        : countriesMap[LocalizationUtils.CountryEnum['Canada']]!;
}

/**
 * @see node_modules/intl-tel-input/build/js/data.js:40
 */
const countriesMap: Partial<Record<LocalizationUtils.CountryEnum, IntlTelInputCountryCode>> = {
    // [LocalizationUtils.CountryEnum['Afghanistan']]: 'af',
    // [LocalizationUtils.CountryEnum['Åland Islands']]: 'ax',
    // [LocalizationUtils.CountryEnum['Albania']]: 'al',
    // [LocalizationUtils.CountryEnum['Algeria']]: 'dz',
    [LocalizationUtils.CountryEnum['American Samoa']]: 'as',
    // [LocalizationUtils.CountryEnum['Andorra']]: 'ad',
    // [LocalizationUtils.CountryEnum['Angola']]: 'ao',
    [LocalizationUtils.CountryEnum['Anguilla']]: 'ai',
    [LocalizationUtils.CountryEnum['Antigua and Barbuda']]: 'ag',
    // [LocalizationUtils.CountryEnum['Argentina']]: 'ar',
    // [LocalizationUtils.CountryEnum['Armenia']]: 'am',
    [LocalizationUtils.CountryEnum['Aruba']]: 'aw',
    // [LocalizationUtils.CountryEnum['Ascension Island']]: 'ac',
    [LocalizationUtils.CountryEnum['Australia']]: 'au',
    // [LocalizationUtils.CountryEnum['Austria']]: 'at',
    // [LocalizationUtils.CountryEnum['Azerbaijan']]: 'az',
    [LocalizationUtils.CountryEnum['Bahamas']]: 'bs',
    // [LocalizationUtils.CountryEnum['Bahrain']]: 'bh',
    // [LocalizationUtils.CountryEnum['Bangladesh']]: 'bd',
    [LocalizationUtils.CountryEnum['Barbados']]: 'bb',
    // [LocalizationUtils.CountryEnum['Belarus']]: 'by',
    // [LocalizationUtils.CountryEnum['Belgium']]: 'be',
    [LocalizationUtils.CountryEnum['Belize']]: 'bz',
    // [LocalizationUtils.CountryEnum['Benin']]: 'bj',
    // [LocalizationUtils.CountryEnum['Bermuda']]: 'bm',
    // [LocalizationUtils.CountryEnum['Bhutan']]: 'bt',
    // [LocalizationUtils.CountryEnum['Bolivia']]: 'bo',
    // [LocalizationUtils.CountryEnum['Bosnia and Herzegovina']]: 'ba',
    [LocalizationUtils.CountryEnum['Botswana']]: 'bw',
    // [LocalizationUtils.CountryEnum['Brazil']]: 'br',
    // [LocalizationUtils.CountryEnum['British Indian Ocean Territory']]: 'io',
    [LocalizationUtils.CountryEnum['British Virgin Islands']]: 'vg',
    [LocalizationUtils.CountryEnum['Brunei']]: 'bn',
    // [LocalizationUtils.CountryEnum['Bulgaria']]: 'bg',
    // [LocalizationUtils.CountryEnum['Burkina Faso']]: 'bf',
    // [LocalizationUtils.CountryEnum['Burundi']]: 'bi',
    // [LocalizationUtils.CountryEnum['Cambodia']]: 'kh',
    [LocalizationUtils.CountryEnum['Cameroon']]: 'cm',
    [LocalizationUtils.CountryEnum['Canada']]: 'ca',
    // [LocalizationUtils.CountryEnum['Cape Verde']]: 'cv',
    // [LocalizationUtils.CountryEnum['Caribbean Netherlands']]: 'bq',
    [LocalizationUtils.CountryEnum['Cayman Islands']]: 'ky',
    // [LocalizationUtils.CountryEnum['Central African Republic']]: 'cf',
    // [LocalizationUtils.CountryEnum['Chad']]: 'td',
    // [LocalizationUtils.CountryEnum['Chile']]: 'cl',
    // [LocalizationUtils.CountryEnum['China']]: 'cn',
    // [LocalizationUtils.CountryEnum['Christmas Island']]: 'cx',
    // [LocalizationUtils.CountryEnum['Cocos (Keeling) Islands']]: 'cc',
    // [LocalizationUtils.CountryEnum['Colombia']]: 'co',
    // [LocalizationUtils.CountryEnum['Comoros']]: 'km',
    // [LocalizationUtils.CountryEnum['Congo (Brazzaville)']]: 'cg',
    // [LocalizationUtils.CountryEnum['Congo (Kinshasa)']]: 'cd',
    [LocalizationUtils.CountryEnum['Cook Islands']]: 'ck',
    // [LocalizationUtils.CountryEnum['Costa Rica']]: 'cr',
    // [LocalizationUtils.CountryEnum["Côte d'Ivoire"]]: 'ci',
    // [LocalizationUtils.CountryEnum['Croatia']]: 'hr',
    // [LocalizationUtils.CountryEnum['Cuba']]: 'cu',
    // [LocalizationUtils.CountryEnum['Curaçao']]: 'cw',
    [LocalizationUtils.CountryEnum['Cyprus']]: 'cy',
    // [LocalizationUtils.CountryEnum['Czech Republic']]: 'cz',
    // [LocalizationUtils.CountryEnum['Denmark']]: 'dk',
    // [LocalizationUtils.CountryEnum['Djibouti']]: 'dj',
    [LocalizationUtils.CountryEnum['Dominica']]: 'dm',
    // [LocalizationUtils.CountryEnum['Dominican Republic']]: 'do',
    // [LocalizationUtils.CountryEnum['Ecuador']]: 'ec',
    // [LocalizationUtils.CountryEnum['Egypt']]: 'eg',
    // [LocalizationUtils.CountryEnum['El Salvador']]: 'sv',
    // [LocalizationUtils.CountryEnum['Equatorial Guinea']]: 'gq',
    // [LocalizationUtils.CountryEnum['Eritrea']]: 'er',
    // [LocalizationUtils.CountryEnum['Estonia']]: 'ee',
    // [LocalizationUtils.CountryEnum['Eswatini']]: 'sz',
    // [LocalizationUtils.CountryEnum['Ethiopia']]: 'et',
    // [LocalizationUtils.CountryEnum['Falkland Islands (Malvinas)']]: 'fk',
    // [LocalizationUtils.CountryEnum['Faroe Islands']]: 'fo',
    [LocalizationUtils.CountryEnum['Fiji']]: 'fj',
    // [LocalizationUtils.CountryEnum['Finland']]: 'fi',
    // [LocalizationUtils.CountryEnum['France']]: 'fr',
    // [LocalizationUtils.CountryEnum['French Guiana']]: 'gf',
    // [LocalizationUtils.CountryEnum['French Polynesia']]: 'pf',
    // [LocalizationUtils.CountryEnum['Gabon']]: 'ga',
    // [LocalizationUtils.CountryEnum['Gambia']]: 'gm',
    // [LocalizationUtils.CountryEnum['Georgia']]: 'ge',
    // [LocalizationUtils.CountryEnum['Germany']]: 'de',
    [LocalizationUtils.CountryEnum['Ghana']]: 'gh',
    [LocalizationUtils.CountryEnum['Gibraltar']]: 'gi',
    // [LocalizationUtils.CountryEnum['Greece']]: 'gr',
    // [LocalizationUtils.CountryEnum['Greenland']]: 'gl',
    [LocalizationUtils.CountryEnum['Grenada']]: 'gd',
    // [LocalizationUtils.CountryEnum['Guadeloupe']]: 'gp',
    [LocalizationUtils.CountryEnum['Guam']]: 'gu',
    // [LocalizationUtils.CountryEnum['Guatemala']]: 'gt',
    [LocalizationUtils.CountryEnum['Guernsey']]: 'gg',
    // [LocalizationUtils.CountryEnum['Guinea']]: 'gn',
    // [LocalizationUtils.CountryEnum['Guinea-Bissau']]: 'gw',
    [LocalizationUtils.CountryEnum['Guyana']]: 'gy',
    // [LocalizationUtils.CountryEnum['Haiti']]: 'ht',
    // [LocalizationUtils.CountryEnum['Honduras']]: 'hn',
    // [LocalizationUtils.CountryEnum['Hong Kong SAR China']]: 'hk',
    // [LocalizationUtils.CountryEnum['Hungary']]: 'hu',
    // [LocalizationUtils.CountryEnum['Iceland']]: 'is',
    [LocalizationUtils.CountryEnum['India']]: 'in',
    // [LocalizationUtils.CountryEnum['Indonesia']]: 'id',
    // [LocalizationUtils.CountryEnum['Iran']]: 'ir',
    // [LocalizationUtils.CountryEnum['Iraq']]: 'iq',
    [LocalizationUtils.CountryEnum['Ireland']]: 'ie',
    [LocalizationUtils.CountryEnum['Isle of Man']]: 'im',
    // [LocalizationUtils.CountryEnum['Israel']]: 'il',
    // [LocalizationUtils.CountryEnum['Italy']]: 'it',
    [LocalizationUtils.CountryEnum['Jamaica']]: 'jm',
    // [LocalizationUtils.CountryEnum['Japan']]: 'jp',
    [LocalizationUtils.CountryEnum['Jersey']]: 'je',
    // [LocalizationUtils.CountryEnum['Jordan']]: 'jo',
    // [LocalizationUtils.CountryEnum['Kazakhstan']]: 'kz',
    [LocalizationUtils.CountryEnum['Kenya']]: 'ke',
    [LocalizationUtils.CountryEnum['Kiribati']]: 'ki',
    // [LocalizationUtils.CountryEnum['Kosovo']]: 'xk',
    // [LocalizationUtils.CountryEnum['Kuwait']]: 'kw',
    // [LocalizationUtils.CountryEnum['Kyrgyzstan']]: 'kg',
    // [LocalizationUtils.CountryEnum['Laos']]: 'la',
    // [LocalizationUtils.CountryEnum['Latvia']]: 'lv',
    // [LocalizationUtils.CountryEnum['Lebanon']]: 'lb',
    // [LocalizationUtils.CountryEnum['Lesotho']]: 'ls',
    [LocalizationUtils.CountryEnum['Liberia']]: 'lr',
    // [LocalizationUtils.CountryEnum['Libya']]: 'ly',
    // [LocalizationUtils.CountryEnum['Liechtenstein']]: 'li',
    // [LocalizationUtils.CountryEnum['Lithuania']]: 'lt',
    // [LocalizationUtils.CountryEnum['Luxembourg']]: 'lu',
    // [LocalizationUtils.CountryEnum['Macao SAR China']]: 'mo',
    // [LocalizationUtils.CountryEnum['Madagascar']]: 'mg',
    [LocalizationUtils.CountryEnum['Malawi']]: 'mw',
    [LocalizationUtils.CountryEnum['Malaysia']]: 'my',
    // [LocalizationUtils.CountryEnum['Maldives']]: 'mv',
    // [LocalizationUtils.CountryEnum['Mali']]: 'ml',
    [LocalizationUtils.CountryEnum['Malta']]: 'mt',
    [LocalizationUtils.CountryEnum['Marshall Islands']]: 'mh',
    // [LocalizationUtils.CountryEnum['Martinique']]: 'mq',
    // [LocalizationUtils.CountryEnum['Mauritania']]: 'mr',
    // [LocalizationUtils.CountryEnum['Mauritius']]: 'mu',
    // [LocalizationUtils.CountryEnum['Mayotte']]: 'yt',
    // [LocalizationUtils.CountryEnum['Mexico']]: 'mx',
    [LocalizationUtils.CountryEnum['Micronesia']]: 'fm',
    // [LocalizationUtils.CountryEnum['Moldova']]: 'md',
    // [LocalizationUtils.CountryEnum['Monaco']]: 'mc',
    // [LocalizationUtils.CountryEnum['Mongolia']]: 'mn',
    // [LocalizationUtils.CountryEnum['Montenegro']]: 'me',
    [LocalizationUtils.CountryEnum['Montserrat']]: 'ms',
    // [LocalizationUtils.CountryEnum['Morocco']]: 'ma',
    // [LocalizationUtils.CountryEnum['Mozambique']]: 'mz',
    // [LocalizationUtils.CountryEnum['Myanmar (Burma)']]: 'mm',
    // [LocalizationUtils.CountryEnum['Namibia']]: 'na',
    [LocalizationUtils.CountryEnum['Nauru']]: 'nr',
    // [LocalizationUtils.CountryEnum['Nepal']]: 'np',
    // [LocalizationUtils.CountryEnum['Netherlands']]: 'nl',
    // [LocalizationUtils.CountryEnum['New Caledonia']]: 'nc',
    [LocalizationUtils.CountryEnum['New Zealand']]: 'nz',
    // [LocalizationUtils.CountryEnum['Nicaragua']]: 'ni',
    // [LocalizationUtils.CountryEnum['Niger']]: 'ne',
    [LocalizationUtils.CountryEnum['Nigeria']]: 'ng',
    [LocalizationUtils.CountryEnum['Niue']]: 'nu',
    // [LocalizationUtils.CountryEnum['Norfolk Island']]: 'nf',
    // [LocalizationUtils.CountryEnum['North Korea']]: 'kp',
    // [LocalizationUtils.CountryEnum['North Macedonia']]: 'mk',
    [LocalizationUtils.CountryEnum['Northern Mariana Islands']]: 'mp',
    // [LocalizationUtils.CountryEnum['Norway']]: 'no',
    // [LocalizationUtils.CountryEnum['Oman']]: 'om',
    [LocalizationUtils.CountryEnum['Pakistan']]: 'pk',
    [LocalizationUtils.CountryEnum['Palau']]: 'pw',
    // [LocalizationUtils.CountryEnum['Palestinian Territories']]: 'ps',
    // [LocalizationUtils.CountryEnum['Panama']]: 'pa',
    [LocalizationUtils.CountryEnum['Papua New Guinea']]: 'pg',
    // [LocalizationUtils.CountryEnum['Paraguay']]: 'py',
    // [LocalizationUtils.CountryEnum['Peru']]: 'pe',
    [LocalizationUtils.CountryEnum['Philippines']]: 'ph',
    // [LocalizationUtils.CountryEnum['Poland']]: 'pl',
    // [LocalizationUtils.CountryEnum['Portugal']]: 'pt',
    // [LocalizationUtils.CountryEnum['Puerto Rico']]: 'pr',
    // [LocalizationUtils.CountryEnum['Qatar']]: 'qa',
    // [LocalizationUtils.CountryEnum['Réunion']]: 're',
    // [LocalizationUtils.CountryEnum['Romania']]: 'ro',
    // [LocalizationUtils.CountryEnum['Russia']]: 'ru',
    // [LocalizationUtils.CountryEnum['Rwanda']]: 'rw',
    [LocalizationUtils.CountryEnum['Samoa']]: 'ws',
    // [LocalizationUtils.CountryEnum['San Marino']]: 'sm',
    // [LocalizationUtils.CountryEnum['São Tomé & Príncipe']]: 'st',
    // [LocalizationUtils.CountryEnum['Saudi Arabia']]: 'sa',
    // [LocalizationUtils.CountryEnum['Senegal']]: 'sn',
    // [LocalizationUtils.CountryEnum['Serbia']]: 'rs',
    // [LocalizationUtils.CountryEnum['Seychelles']]: 'sc',
    [LocalizationUtils.CountryEnum['Sierra Leone']]: 'sl',
    [LocalizationUtils.CountryEnum['Singapore']]: 'sg',
    // [LocalizationUtils.CountryEnum['Sint Maarten']]: 'sx',
    // [LocalizationUtils.CountryEnum['Slovakia']]: 'sk',
    // [LocalizationUtils.CountryEnum['Slovenia']]: 'si',
    [LocalizationUtils.CountryEnum['Solomon Islands']]: 'sb',
    // [LocalizationUtils.CountryEnum['Somalia']]: 'so',
    [LocalizationUtils.CountryEnum['South Africa']]: 'za',
    // [LocalizationUtils.CountryEnum['South Korea']]: 'kr',
    // [LocalizationUtils.CountryEnum['South Sudan']]: 'ss',
    // [LocalizationUtils.CountryEnum['Spain']]: 'es',
    // [LocalizationUtils.CountryEnum['Sri Lanka']]: 'lk',
    // [LocalizationUtils.CountryEnum['St. Barthélemy']]: 'bl',
    // [LocalizationUtils.CountryEnum['St. Helena']]: 'sh',
    // [LocalizationUtils.CountryEnum['St. Kitts & Nevis']]: 'kn',
    // [LocalizationUtils.CountryEnum['St. Lucia']]: 'lc',
    // [LocalizationUtils.CountryEnum['St. Martin']]: 'mf',
    // [LocalizationUtils.CountryEnum['St. Pierre & Miquelon']]: 'pm',
    // [LocalizationUtils.CountryEnum['St. Vincent & Grenadines']]: 'vc',
    [LocalizationUtils.CountryEnum['Sudan']]: 'sd',
    // [LocalizationUtils.CountryEnum['Suriname']]: 'sr',
    // [LocalizationUtils.CountryEnum['Svalbard & Jan Mayen']]: 'sj',
    // [LocalizationUtils.CountryEnum['Sweden']]: 'se',
    // [LocalizationUtils.CountryEnum['Switzerland']]: 'ch',
    // [LocalizationUtils.CountryEnum['Syria']]: 'sy',
    // [LocalizationUtils.CountryEnum['Taiwan']]: 'tw',
    // [LocalizationUtils.CountryEnum['Tajikistan']]: 'tj',
    [LocalizationUtils.CountryEnum['Tanzania']]: 'tz',
    // [LocalizationUtils.CountryEnum['Thailand']]: 'th',
    // [LocalizationUtils.CountryEnum['Timor-Leste']]: 'tl',
    // [LocalizationUtils.CountryEnum['Togo']]: 'tg',
    // [LocalizationUtils.CountryEnum['Tokelau']]: 'tk',
    [LocalizationUtils.CountryEnum['Tonga']]: 'to',
    [LocalizationUtils.CountryEnum['Trinidad and Tobago']]: 'tt',
    // [LocalizationUtils.CountryEnum['Tunisia']]: 'tn',
    // [LocalizationUtils.CountryEnum['Turkey']]: 'tr',
    // [LocalizationUtils.CountryEnum['Turkmenistan']]: 'tm',
    // [LocalizationUtils.CountryEnum['Turks & Caicos Islands']]: 'tc',
    [LocalizationUtils.CountryEnum['Tuvalu']]: 'tv',
    [LocalizationUtils.CountryEnum['Uganda']]: 'ug',
    // [LocalizationUtils.CountryEnum['Ukraine']]: 'ua',
    // [LocalizationUtils.CountryEnum['United Arab Emirates']]: 'ae',
    [LocalizationUtils.CountryEnum['United Kingdom']]: 'gb',
    [LocalizationUtils.CountryEnum['United States']]: 'us',
    // [LocalizationUtils.CountryEnum['Uruguay']]: 'uy',
    // [LocalizationUtils.CountryEnum['.S. Virgin Islands']]: 'vi',
    // [LocalizationUtils.CountryEnum['Uzbekistan']]: 'uz',
    [LocalizationUtils.CountryEnum['Vanuatu']]: 'vu',
    // [LocalizationUtils.CountryEnum['Vatican City']]: 'va',
    // [LocalizationUtils.CountryEnum['Venezuela']]: 've',
    // [LocalizationUtils.CountryEnum['Vietnam']]: 'vn',
    // [LocalizationUtils.CountryEnum['Wallis & Futuna']]: 'wf',
    // [LocalizationUtils.CountryEnum['Western Sahara']]: 'eh',
    // [LocalizationUtils.CountryEnum['Yemen']]: 'ye',
    [LocalizationUtils.CountryEnum['Zambia']]: 'zm',
    [LocalizationUtils.CountryEnum['Zimbabwe']]: 'zw',
};
