import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, enableProdMode, isDevMode } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withDebugTracing } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { providePlatformLocalStorage, provideWindow } from '@common/utils';

import {
    apiUrlInterceptor,
    provideApplicationFeatures,
    provideEnvironment,
    provideLocalStorage,
    provideTenantId,
} from '@portal/core/utils';

import { getAppRoutes } from './app.routes';
import { environment } from '../environments/environment';

if (environment.production) {
    enableProdMode();
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
        provideEnvironment(environment),
        provideApplicationFeatures(),
        provideWindow(),
        providePlatformLocalStorage(),
        provideLocalStorage(),
        provideTenantId(),
        provideRouter(getAppRoutes(isDevMode()), ...(isDevMode() ? [withDebugTracing()] : []), withComponentInputBinding()),
        provideHttpClient(withInterceptors([apiUrlInterceptor])),
        provideStore(),
        ...(isDevMode()
            ? [
                  provideStoreDevtools({
                      name: 'CaseRM Silo',
                      maxAge: 25, // Retains last 25 states
                      logOnly: !isDevMode(), // Restrict extension to log-only mode
                      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
                      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
                      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
                      connectInZone: true, // If set to true, the connection is established within the Angular zone
                  }),
              ]
            : []),
    ],
};
