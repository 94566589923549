import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MaxLength } from '@portal/shared/utils';

@Component({
    templateUrl: './note-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogClose,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogContent,
        MatDialogActions,
        ReactiveFormsModule,
        MatDialogTitle,
    ],
})
export class NoteDialogComponent {
    public readonly data? = inject<{ note: string }>(MAT_DIALOG_DATA);

    public readonly noteChange = output<string>();
    public readonly NOTE_MAX_LENGTH = MaxLength.NOTE_MAX_LENGTH;

    public readonly createNote: FormGroup;
    private readonly fb: FormBuilder = inject(FormBuilder);

    constructor() {
        const data = this.data;

        this.createNote = this.fb.group({
            note: [data?.note ?? '', [Validators.maxLength(this.NOTE_MAX_LENGTH)]],
        });
    }

    public onCreate(): void {
        if (this.createNote.valid) {
            const noteValue = this.createNote.get('note')!.value as string;
            this.noteChange.emit(noteValue);
        }
    }
}
