import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'lib-ui-content-loader-snippet',
    template: ` <div class="flex h-full w-full items-center justify-center">
        <mat-spinner strokeWidth="1" />
    </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatProgressSpinner],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ContentLoaderSnippet {}
