import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';

import {
    ContainerContentDirective,
    ContainerPanelDirective,
    ContainerShellApiDirective,
    ContainerShellComponent,
} from '../../container-shell';

@Component({
    templateUrl: './sidenav.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'p-ui-sidenav',
    imports: [
        ContainerShellComponent,
        ContainerShellApiDirective,
        MatButton,
        MatDivider,
        ContainerContentDirective,
        ContainerPanelDirective,
    ],
})
export class SidenavComponent {}
