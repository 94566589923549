<h2 mat-dialog-title>Delete file?</h2>
<mat-dialog-content>
    <div class="flex p-4">This action cannot be undone.</div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mb-4 mr-4 flex w-full justify-end">
        <button mat-button mat-dialog-close>Keep</button>
        <button mat-raised-button class="primary" (click)="onDeleteFile()">Delete</button>
    </div>
</mat-dialog-actions>
