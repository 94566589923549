import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SidenavComponent } from './sidenav/sidenav.component';

@Component({
    templateUrl: './sandbox.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SidenavComponent],
})
export class SandboxComponent {}
