<h2 mat-dialog-title>
    Reset password for
    <div class="mt-2 flex gap-1">
        <span class="text-lg">{{ data.firstName }}</span>
        @if (data.lastName) {
            <span class="text-lg">{{ data.lastName }}</span>
        }
    </div>
</h2>

<mat-dialog-content>
    <form [formGroup]="resetPasswordForm" novalidate class="w-96 pt-1">
        <div class="flex flex-col gap-3">
            <mat-form-field appearance="outline" class="mt-2 w-full">
                <mat-label>Password</mat-label>
                <input matInput placeholder="Password" formControlName="password" #inputElement (focus)="inputElement.select()" />
                @if (inputElement.value) {
                    <button matSuffix mat-icon-button aria-label="Copy" (click)="copyToClipboard(inputElement.value)" matTooltip="Copy">
                        <mat-icon class="outlined">content_copy</mat-icon>
                    </button>
                }
            </mat-form-field>

            <div class="text-ds-purple-500 my-1 cursor-pointer" (click)="generatePassword()">Generate password</div>

            <mat-checkbox formControlName="sendNotification" class="py-2">Send the new user updated password</mat-checkbox>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mb-4 mr-4 flex w-full justify-end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button class="primary" (click)="onResetPassword()" [disabled]="resetPasswordForm.invalid">Reset</button>
    </div>
</mat-dialog-actions>
