import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, Observable } from 'rxjs';

import { IsNotUndefined, IsTrue } from '@portal/shared/utils';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ConfirmationDialogData } from './confirmation-dialog.data';
import { NotificationDialogComponent } from './notification-dialog.component';
import { NotificationDialogData } from './notification-dialog.data';

@Injectable({ providedIn: 'root' })
export class SystemDialogService {
    private readonly dialog: MatDialog = inject(MatDialog);

    public confirm(data: ConfirmationDialogData): Observable<boolean> {
        const dialogRef = this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
            data,
        });

        return dialogRef.afterClosed().pipe(filter(IsNotUndefined));
    }

    public defaultDiscardConfirm(): Observable<boolean> {
        return this.confirm({
            title: 'Discard changes',
            message: 'Are you sure you want to discard changes',
        });
    }

    public onDefaultDiscardConfirm(): Observable<void> {
        return this.defaultDiscardConfirm().pipe(
            filter(IsTrue),
            map(() => void 0)
        );
    }

    public notify(data: NotificationDialogData): Observable<void> {
        const dialogRef = this.dialog.open<NotificationDialogComponent, NotificationDialogData, void>(NotificationDialogComponent, {
            data,
        });

        return dialogRef.afterClosed();
    }
}
