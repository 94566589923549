<div class="flex h-screen flex-col bg-gray-200">
    <div class="flex flex-grow items-center justify-center p-10">
        <div class="text-center">
            <mat-icon class="mb-4 text-9xl text-red-500">block</mat-icon>
            <h1 class="mb-6 text-5xl font-bold text-gray-800">403 - Forbidden</h1>
            <p class="mb-6 text-lg text-gray-600">Sorry, you don't have access to this page.</p>
            <p class="text-md mb-10 text-gray-500">
                If you think this is a mistake, please
                <a href="mailto:support@yourdomain.com" class="text-blue-600 hover:underline">contact support</a>.
            </p>
            <button
                mat-stroked-button
                routerLink="/"
                class="primary border border-blue-500 px-8 py-3 text-blue-500 transition-colors duration-300 hover:bg-blue-500 hover:text-white"
            >
                Go to Homepage
            </button>
        </div>
    </div>
    <footer class="w-full bg-gray-300 p-4 text-center">
        <div class="text-sm text-gray-700">
            Need help? Contact us:
            <a href="mailto:help@yourdomain.com" class="text-blue-600 hover:underline">help.domain.com</a> |
            <a href="tel:+1234567890" class="text-blue-600 hover:underline">+1 (234) 567-890</a>
        </div>
    </footer>
</div>
