import { ConnectionPositionPair } from '@angular/cdk/overlay';

import { INDENTATION } from './constants';
import { HorizontalConnections, VerticalConnections } from './overlay-positions';
import { PortalPlacement } from './portal-placement';

/**
 * @description
 * Configurations of the overlay positions follow the pattern of a clock's numbers.
 * Starting from '12' which denotes the topmost point, positions move in a clockwise direction.
 * For example, '3' would represent to the right of the overlay, '6' at the bottom, and '9' to the left.
 *
 * ```
 *              12
 *       11            1
 *       ---------------
 *   10  |             |   2
 *       |             |
 * 9     |             |     3
 *       |             |
 *   8   |             |   4
 *       --------------
 *       7             5
 *              6
 * ```
 */

const TOP_11: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
];

const TOP_12: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
];

const TOP_1: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
];

const RIGHT_2: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.bottom },
        INDENTATION,
        0
    ),
];

const RIGHT_3: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.center },
        INDENTATION,
        0
    ),
];

const RIGHT_4: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top },
        INDENTATION,
        0
    ),
];

const BOTTOM_5: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.end, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.start, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
];

const BOTTOM_6: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.center, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.center, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
];

const BOTTOM_7: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.top },
        0,
        INDENTATION
    ),
];

const LEFT_8: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.bottom },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.top },
        0,
        -INDENTATION
    ),
];

const LEFT_9: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.center },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.center },
        0,
        -INDENTATION
    ),
];

const LEFT_10: ConnectionPositionPair[] = [
    new ConnectionPositionPair(
        { originX: HorizontalConnections.start, originY: VerticalConnections.top },
        { overlayX: HorizontalConnections.end, overlayY: VerticalConnections.bottom },
        0,
        -INDENTATION
    ),
];

const ClockwisePortalPlacement = [
    ...TOP_12,
    ...TOP_1,
    ...RIGHT_2,
    ...RIGHT_3,
    ...RIGHT_4,
    ...BOTTOM_5,
    ...BOTTOM_6,
    ...BOTTOM_7,
    ...LEFT_8,
    ...LEFT_9,
    ...LEFT_10,
    ...TOP_11,
];

const CounterclockwisePortalPlacement = [
    ...TOP_12,
    ...TOP_11,
    ...LEFT_10,
    ...LEFT_9,
    ...LEFT_8,
    ...BOTTOM_7,
    ...BOTTOM_6,
    ...BOTTOM_5,
    ...RIGHT_4,
    ...RIGHT_3,
    ...RIGHT_2,
    ...TOP_1,
];

export const ClockPortalPositionsMap = new Map<PortalPlacement, ConnectionPositionPair[]>([
    [PortalPlacement.Clockwise, ClockwisePortalPlacement],
    [PortalPlacement.Counterclockwise, CounterclockwisePortalPlacement],
]);
