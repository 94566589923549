@let isPanelVisible = $isPanelVisible();

<div class="shadow-3xl my-3 w-full rounded-md bg-white p-4">
    <button
        (click)="onChangeVisibility()"
        class="text-ds-purple-500 flex w-full flex-row items-center justify-between rounded-bl-md rounded-br-md rounded-tl-md rounded-tr-md px-4 py-2"
        [ngClass]="{ '!rounded-br-none': isPanelVisible, '!rounded-bl-none': isPanelVisible }"
    >
        <span class="text-sm">Add files</span>
        <mat-icon [class.rotate-180]="isPanelVisible">expand_more</mat-icon>
    </button>
    @if (isPanelVisible) {
        <div class="rounded-br-md p-3">
            <div
                class="text-ds-purple-500 flex min-h-[220px] flex-col items-center justify-center gap-3 border-2 border-dashed border-gray-500"
                [ngClass]="{ 'border-gray-500': !isDragging, 'border-current': isDragging }"
                (dragover)="onDragOver($event)"
                (dragleave)="onDragLeave($event)"
                (drop)="onDrop($event)"
            >
                @if (isDragging) {
                    <div class="py-[68px]">Drop files to instantly upload</div>
                } @else {
                    <span class="text-sm">Drop file to upload</span>
                    <span class="text-sm">or</span>
                    <button class="primary" (click)="fileInput.click()" mat-raised-button>Browse</button>
                    <input #fileInput type="file" multiple="true" class="hidden" (change)="onFileSelected($event)" />
                    <div class="text-sm">{{ selectedFile?.name }}</div>
                    <span class="text-sm">Maximum upload file size 25 MB</span>
                }
            </div>
        </div>
    }
</div>
