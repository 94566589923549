import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationResource, JwtResponseDto, LoginDto, UserDto } from '@portal/shared/utils';

@Injectable()
export class AuthenticationResourceController {
    private readonly _resource: AuthenticationResource = inject(AuthenticationResource);

    public login(loginDto: LoginDto): Observable<JwtResponseDto> {
        return this._resource.login(loginDto);
    }

    public refreshToken(refreshToken: string): Observable<JwtResponseDto> {
        return this._resource.refreshToken({ refreshToken });
    }

    public logout(): Observable<void> {
        return this._resource.logout();
    }

    public getUserInfo(): Observable<UserDto> {
        return this._resource.getUserInfo();
    }
}
