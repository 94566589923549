import { Provider } from '@angular/core';

import { ApplicationFeatures, ApplicationFeaturesAvailability, ApplicationFeaturesAvailabilityToken } from '@portal/shared/utils';

import { useIsDevelopment, useIsProduction, useIsStage } from '../contexts';

function applicationFeaturesAvailabilityFactory(): ApplicationFeaturesAvailability {
    const isDevelopment = useIsDevelopment();
    const isStage = useIsStage();
    const isProduction = useIsProduction();

    return {
        [ApplicationFeatures.CALENDAR_APPLICATION]: isDevelopment || isStage,
        [ApplicationFeatures.CASE_FILE_APPLICATION]: isDevelopment || isStage,
        [ApplicationFeatures.CONTACTS_APPLICATION]: isDevelopment || isStage || isProduction,
        [ApplicationFeatures.ACCOUNTING_APPLICATION]: isDevelopment || isStage,
        [ApplicationFeatures.CHARTING_APPLICATION]: isDevelopment,
        [ApplicationFeatures.TEAM_APPLICATION]: isDevelopment || isStage,
        [ApplicationFeatures.ADMIN_CONSOLE_APPLICATION]: isDevelopment || isStage,
        [ApplicationFeatures.ACCOUNT_PROFILE_MODAL]: isDevelopment || isStage,
        [ApplicationFeatures.DEVELOPMENT_APPLICATION]: isDevelopment,
        [ApplicationFeatures.USER_APPLICATION]: isDevelopment || isStage || isProduction,
        [ApplicationFeatures.EXTERNALIZE_SECURITY_SUPPORT]: isProduction,
    };
}

export function provideApplicationFeatures(): Provider {
    return [
        {
            provide: ApplicationFeaturesAvailabilityToken,
            useFactory: applicationFeaturesAvailabilityFactory,
        },
    ];
}
