import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { OptionModel, UserDto, UserRoles } from '@portal/shared/utils';

@Component({
    templateUrl: './change-role-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        MatDialogClose,
        MatButtonModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogContent,
        MatIconModule,
        MatDialogActions,
        MatSelectModule,
        MatDialogTitle,
        ReactiveFormsModule,
        MatDialogTitle,
    ],
})
export class ChangeRoleDialogComponent {
    public readonly data = inject<UserDto>(MAT_DIALOG_DATA);

    public readonly changeUserRole = output<UserDto>();

    public readonly roles: Array<OptionModel<UserRoles>> = [
        {
            value: UserRoles.MANAGER,
            label: 'Manager',
        },
        {
            value: UserRoles.OFFICE_ADMIN,
            label: 'Office Admin',
        },
        {
            value: UserRoles.PROVIDER,
            label: 'Provider',
        },
        {
            value: UserRoles.DIRECTOR,
            label: 'Director',
        },
    ];

    public readonly changeRoleForm: FormGroup;
    private readonly fb: FormBuilder = inject(FormBuilder);

    constructor() {
        this.changeRoleForm = this.fb.group({
            roles: [this.data?.roles?.[0] ?? UserRoles.MANAGER],
        });
    }

    public onChangeRole() {
        const selectedRole = this.changeRoleForm.get('roles')?.value;
        const updatedUserData = {
            ...this.data,
            roles: [selectedRole],
        };
        this.changeUserRole.emit(updatedUserData);
    }
}
