@if (clockwise) {
  <pdf-shy-button
    [action]="rotateCW"
    primaryToolbarId="primaryPageRotateCw"
    class="rotateCw"
    [cssClass]="showRotateCwButton | responsiveCSSClass : 'hiddenXLView'"
    title="Rotate Clockwise"
    l10nId="pdfjs-page-rotate-cw-button"
    l10nLabel="pdfjs-page-rotate-cw-button-label"
    [disabled]="disableRotate"
    [order]="900"
    [closeOnClick]="false"
    image="<svg class='width-height-23px' viewBox='0 0 24 24'><path fill='currentColor' d='M12 3C7.03 3 3 7.03 3 12S7.03 21 12 21C14 21 15.92 20.34 17.5 19.14L16.06 17.7C14.87 18.54 13.45 19 12 19C8.13 19 5 15.87 5 12S8.13 5 12 5 19 8.13 19 12H16L20 16L24 12H21C21 7.03 16.97 3 12 3'/></svg>"
    >
  </pdf-shy-button>
}
