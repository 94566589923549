<mat-dialog-content>
    <div class="flex flex-wrap gap-5">
        <mat-icon class="mt-2 scale-[2]">account_circle</mat-icon>
        <div class="flex flex-col">
            <div class="flex flex-col">
                <div>Bob Simons</div>
                <div>hrhrrht&#64;rthrth.gfnr</div>
            </div>
            <div class="text-ds-purple-500 ml-2 mt-4 max-w-max cursor-pointer">View profile</div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="mb-4 mr-4 flex w-full justify-end">
        <button mat-raised-button class="primary">Sign out</button>
    </div>
</mat-dialog-actions>
