import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';

@Component({
    templateUrl: './delete-file-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle],
})
export class DeleteFileDialogComponent {
    public readonly data = inject<{
        fileId: number;
    }>(MAT_DIALOG_DATA);

    public readonly deleteFile = output<number>();

    public onDeleteFile() {
        this.deleteFile.emit(this.data.fileId);
    }
}
