/* eslint-disable @angular-eslint/no-input-rename */

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Directive, DoCheck, ElementRef, inject, Input, signal, WritableSignal } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm, Validators } from '@angular/forms';
import { _ErrorStateTracker, ErrorStateMatcher } from '@angular/material/core';
import { MAT_FORM_FIELD, MatFormFieldControl } from '@angular/material/form-field';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { coloris, init as colorisInit } from '@melloware/coloris';
import { Observable, Subject } from 'rxjs';

import { BASE_COLORS } from '@portal/shared/utils';

export type PUiColorisOptions = Partial<Omit<Parameters<typeof coloris>[0], 'el' | 'focusInput' | 'wrap'>>;

@Directive({
    selector: 'input[type=text][pUiColoris]',
    host: {
        class: 'mat-mdc-input-element',
        '[class.mat-mdc-form-field-input-control]': 'isInFormField',
        '[class.mdc-text-field__input]': 'isInFormField',
        // Native input properties that are overwritten by Angular inputs need to be synced with
        // the native input element. Otherwise, property bindings for those don't work.
        '[id]': 'id',
        '[disabled]': 'disabled',
        '[required]': 'required',
        '[attr.readonly]': '_getReadonlyAttribute()',
        '[attr.aria-disabled]': 'disabled? "true" : null',
        // Only mark the input as invalid for assistive technology if it has a value since the
        // state usually overlaps with `aria-required` when the input is empty and can be redundant.
        '[attr.aria-invalid]': '(empty && required) ? null : errorState',
        '[attr.aria-required]': 'required',
        // Native input properties that are overwritten by Angular inputs need to be synced with
        // the native input element. Otherwise, property bindings for those don't work.
        '[attr.id]': 'id',
        // Note: The browser has a 'color' input type, but this component should support user input.
        '[attr.type]': '"text"',
        '(focus)': 'focusChanged(true)',
        '(blur)': 'focusChanged(false)',
        '(input)': 'onInput()',
    },
    providers: [{ provide: MatFormFieldControl, useExisting: InputColorisDirective }],
})
export class InputColorisDirective implements AfterViewInit, MatFormFieldControl<string>, DoCheck {
    private static nextUniqueId: number = 0;
    readonly #defaultColorisOptions: PUiColorisOptions = {
        theme: 'polaroid',
        themeMode: 'auto',
        alpha: true,
        forceAlpha: true,
        format: 'hex',
    };

    #previousPlaceholder!: string | null;
    readonly #stateChangesSource: Subject<void> = new Subject<void>();

    protected readonly isInFormField: boolean = inject(MAT_FORM_FIELD, { optional: true }) !== null;

    readonly #elementRef: ElementRef<HTMLInputElement> = inject(ElementRef);
    readonly #inputValueAccessor: { value: string } =
        inject(MAT_INPUT_VALUE_ACCESSOR, { optional: true, self: true }) ?? this.#elementRef.nativeElement;

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    public readonly ngControl: NgControl | null = inject(NgControl, { optional: true, self: true });

    readonly #errorStateTracker: _ErrorStateTracker = new _ErrorStateTracker(
        inject(ErrorStateMatcher),
        this.ngControl,
        inject(FormGroupDirective, { optional: true }),
        inject(NgForm, { optional: true }),
        this.#stateChangesSource
    );

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    public readonly stateChanges: Observable<void> = this.#stateChangesSource.asObservable();

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    public readonly controlType: string = 'p-ui-input-coloris';

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    @Input() public placeholder!: string;

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    @Input()
    public get id(): string {
        return this.#$idSource();
    }
    public set id(value: string) {
        if (value) {
            this.#$idSource.set(value);
        }
    }
    readonly #$idSource: WritableSignal<string> = signal(`${this.controlType}-${InputColorisDirective.nextUniqueId++}`);

    /** Whether the element is readonly. */
    @Input()
    public get readonly(): boolean {
        return this._readonly;
    }
    public set readonly(value: BooleanInput) {
        this._readonly = coerceBooleanProperty(value);
    }
    private _readonly = false;

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    @Input('aria-describedby') public userAriaDescribedBy!: string;

    @Input() public pUiColorisOptions?: PUiColorisOptions;

    @Input() public swatches: string[] = [...BASE_COLORS];

    public ngAfterViewInit(): void {
        colorisInit();
        coloris({
            ...this.#defaultColorisOptions,
            ...this.pUiColorisOptions,
            el: this.#elementRef.nativeElement,
            wrap: false,
            focusInput: true,
            swatches: this.swatches ?? [],
        });
    }

    public ngDoCheck(): void {
        if (this.ngControl) {
            // We need to re-evaluate this on every change detection cycle, because there are some
            // error triggers that we can't subscribe to (e.g. parent form submissions). This means
            // that whatever logic is in here has to be super lean or we risk destroying the performance.
            this.#updateErrorState();

            // Since the input isn't a `ControlValueAccessor`, we don't have a good way of knowing when
            // the disabled state has changed. We can't use the `ngControl.statusChanges`, because it
            // won't fire if the input is disabled with `emitEvents = false`, despite the input becoming
            // disabled.
            if (this.ngControl.disabled !== null && this.ngControl.disabled !== this.disabled) {
                this.disabled = this.ngControl.disabled;
                this.#stateChangesSource.next();
            }
        }

        // We need to dirty-check and set the placeholder attribute ourselves, because whether it's
        // present or not depends on a query which is prone to "changed after checked" errors.
        this.#dirtyCheckPlaceholder();
    }

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    @Input()
    get disabled(): boolean {
        return this._disabled;
    }
    set disabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);

        // Browsers may not fire the blur event if the input is disabled too quickly.
        // Reset from here to ensure that the element doesn't become stuck.
        if (this.focused) {
            this.focused = false;
            this.#stateChangesSource.next();
        }
    }
    protected _disabled = false;

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    public get empty(): boolean {
        return !this.#elementRef.nativeElement.value?.trim() && !this.#isBadInput();
    }

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    get errorState() {
        return this.#errorStateTracker.errorState;
    }
    set errorState(value: boolean) {
        this.#errorStateTracker.errorState = value;
    }

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    public focused: boolean = false;

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    public onContainerClick(): void {
        if (!this.focused) {
            this.#openColoris();
            this.#focus();
        }
    }

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    @Input()
    public get required(): boolean {
        return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
    }
    public set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
    }
    protected _required: boolean | undefined;

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    public setDescribedByIds(ids: string[]) {
        if (ids.length) {
            this.#elementRef.nativeElement.setAttribute('aria-describedby', ids.join(' '));
        } else {
            this.#elementRef.nativeElement.removeAttribute('aria-describedby');
        }
    }

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    public get shouldLabelFloat(): boolean {
        return (this.focused && !this.disabled) || !this.empty;
    }

    /**
     * Implemented as part of MatFormFieldControl.
     * @docs-private
     */
    @Input()
    get value(): string {
        return this.#inputValueAccessor.value;
    }
    set value(value: any) {
        if (value !== this.value) {
            this.#inputValueAccessor.value = value;
            this.#stateChangesSource.next();
        }
    }

    /** Callback for the cases where the focused state of the input changes. */
    protected focusChanged(isFocused: boolean) {
        if (isFocused === this.focused) {
            return;
        }

        if (isFocused && this.disabled) {
            (this.#elementRef.nativeElement as HTMLInputElement).setSelectionRange(0, 0);
        }

        this.focused = isFocused;
        this.#stateChangesSource.next();
    }

    protected onInput(): void {
        // Leave empty to ensure proper change detection for asynchronous updates
    }

    /** Gets the value to set on the `readonly` attribute. */
    protected _getReadonlyAttribute(): string | null {
        if (this.readonly || this.disabled) {
            return 'true';
        }

        return null;
    }

    /** Checks whether the input is invalid based on the native validation. */
    #isBadInput(): boolean {
        return (this.#elementRef.nativeElement as HTMLInputElement).validity?.badInput ?? true;
    }

    /** Focuses the input. */
    #focus(options?: FocusOptions): void {
        this.#elementRef.nativeElement.focus(options);
    }

    /** Refreshes the error state of the input. */
    #updateErrorState(): void {
        this.#errorStateTracker.updateErrorState();
    }

    /** Does some manual dirty checking on the native input `placeholder` attribute. */
    #dirtyCheckPlaceholder() {
        const placeholder = this.#getPlaceholder();
        if (placeholder !== this.#previousPlaceholder) {
            const element = this.#elementRef.nativeElement;
            this.#previousPlaceholder = placeholder;
            typeof placeholder === 'string' ? element.setAttribute('placeholder', placeholder) : element.removeAttribute('placeholder');
        }
    }

    /** Gets the current placeholder of the form field. */
    #getPlaceholder(): string | null {
        return this.placeholder || null;
    }

    #openColoris(): void {
        // Trigger a click event to open the color picker dialog
        this.#elementRef.nativeElement.click();
    }
}
