import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';

import { ConfirmationDialogData } from './confirmation-dialog.data';

@Component({
    template: `
        <h1 mat-dialog-title>{{ data.title }}</h1>
        <mat-dialog-content>
            <p class="w-full py-2">{{ data.message }}</p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button [mat-dialog-close]="false" type="button">{{ data.cancelButtonText || 'Cancel' }}</button>
            <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial type="button" class="primary">
                {{ data.confirmButtonText || 'Confirm' }}
            </button>
        </mat-dialog-actions>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose],
})
export class ConfirmationDialogComponent {
    public readonly data = inject<ConfirmationDialogData>(MAT_DIALOG_DATA);
}
