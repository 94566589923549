import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { NgComponentOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    forwardRef,
    inject,
    input,
    Signal,
    signal,
    viewChild,
    WritableSignal,
} from '@angular/core';

import { PortalPlacement, PortalPositionsUtils } from '@portal/shared/ui';

import { PriorityNavigationService } from '../priority-navigation.service';
import { HostItem } from './host-item';
import { PriorityHostItemComponent } from './priority-host-item.component';

@Component({
    imports: [PriorityHostItemComponent, CdkConnectedOverlay, CdkOverlayOrigin, NgComponentOutlet],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div (click)="onToggle()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
            <p-ft-priority-host-item [isActiveBackground]="false" [isActiveIcon]="$isOpen()" [icon]="$icon()" [title]="$title()" />
        </div>

        <ng-template
            cdkConnectedOverlay
            (backdropClick)="onClose()"
            [cdkConnectedOverlayHasBackdrop]="true"
            [cdkConnectedOverlayOpen]="$isOpen()"
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayPositions]="positions"
            [cdkConnectedOverlayBackdropClass]="'transparent'"
            [cdkConnectedOverlayPanelClass]="['bg-ds-light-500', 'shadow-xl', 'rounded-md', 'border', 'p-4', 'border-ds-purple-400']"
        >
            <div class="flex flex-col gap-4">
                @for (hostItemsRow of $hostItemsRows(); track $index) {
                    <div class="flex gap-4">
                        @for (hostItem of hostItemsRow; track hostItem.id) {
                            <div>
                                <ng-template [ngComponentOutlet]="hostItem.host" [ngComponentOutletInputs]="hostItem.hostInputs" />
                            </div>
                        }
                    </div>
                }
            </div>
        </ng-template>
    `,
    providers: [
        {
            provide: HostItem,
            useValue: forwardRef(() => MoreHostItemComponent),
        },
    ],
})
export class MoreHostItemComponent {
    protected readonly $overlay: Signal<CdkConnectedOverlay | undefined> = viewChild.required(CdkConnectedOverlay);

    protected readonly $icon = input<string>('more_horiz', { alias: 'icon' });
    protected readonly $title = input<string | null>(null, { alias: 'title' });
    protected readonly $hiddenCount = input<number | null>(null, { alias: 'hiddenCount' });
    protected readonly $hiddenIndex = input<number | null>(null, { alias: 'hiddenIndex' });

    private static readonly rowSize: number = 3;

    readonly #navigationAppsService = inject(PriorityNavigationService);

    protected $hostItemsRows = computed(() => {
        const hiddenIndex = this.$hiddenIndex();

        if (hiddenIndex) {
            const appHostItems = this.#navigationAppsService.$appHostItems();

            return splitIntoChunks(appHostItems.slice(hiddenIndex), MoreHostItemComponent.rowSize);
        }

        return null;
    });

    readonly #isOpenSource: WritableSignal<boolean> = signal<boolean>(false);
    protected $isOpen = this.#isOpenSource.asReadonly();

    protected readonly positions: ConnectionPositionPair[] = PortalPositionsUtils.getPositions(PortalPlacement.RightTop);

    constructor() {
        effect(() => {
            const overlay: CdkConnectedOverlay | undefined = this.$overlay();
            const hiddenCount = this.$hiddenCount();

            if (overlay?.overlayRef && hiddenCount === 0) {
                overlay.overlayRef.backdropElement?.click();
            }
        });
    }

    protected onClose() {
        this.#isOpenSource.set(false);
    }

    protected onToggle() {
        this.#isOpenSource.update((state) => !state);
    }
}

function splitIntoChunks<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        chunks.push(chunk);
    }
    return chunks;
}
