import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';

export const HorizontalConnections: { [K in HorizontalConnectionPos]: K } = {
    start: 'start',
    center: 'center',
    end: 'end',
};

export const VerticalConnections: { [K in VerticalConnectionPos]: K } = {
    top: 'top',
    center: 'center',
    bottom: 'bottom',
};
