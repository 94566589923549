import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { transformToNumber } from '@portal/shared/utils';

@Component({
    selector: 'p-ui-pagination',
    templateUrl: './pagination.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatIconButton],
})
export class PaginationComponent {
    @Input({ transform: transformToNumber }) public currentPage!: number;
    @Input({ transform: transformToNumber }) public totalPages!: number;
    public readonly pageChange = output<number>();

    public onNext() {
        if (this.currentPage < this.totalPages - 1) {
            this.pageChange.emit(this.currentPage + 1);
        }
    }

    public onPrevious() {
        if (this.currentPage > 0) {
            this.pageChange.emit(this.currentPage - 1);
        }
    }
}
